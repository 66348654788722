import { emptyApi as api } from "../configuration/emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createNewRunProjectsProjectIdRunsPost: build.mutation<
      CreateNewRunProjectsProjectIdRunsPostApiResponse,
      CreateNewRunProjectsProjectIdRunsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/projects/${queryArg.projectId}/runs`,
        method: "POST",
        params: {
          requestedMeasurementDate: queryArg.requestedMeasurementDate,
          methodology: queryArg.methodology,
          priority: queryArg.priority,
        },
      }),
    }),
    setProjectDetailsProjectsProjectIdPatch: build.mutation<
      SetProjectDetailsProjectsProjectIdPatchApiResponse,
      SetProjectDetailsProjectsProjectIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/projects/${queryArg.projectId}`,
        method: "PATCH",
        params: { state: queryArg.state, owner: queryArg.owner },
      }),
    }),
    setRunStatusProjectsProjectIdRunsRunIdPatch: build.mutation<
      SetRunStatusProjectsProjectIdRunsRunIdPatchApiResponse,
      SetRunStatusProjectsProjectIdRunsRunIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/projects/${queryArg.projectId}/runs/${queryArg.runId}`,
        method: "PATCH",
        params: { state: queryArg.state },
      }),
    }),
    listJobRequestWorkloadsRequestsGet: build.query<
      ListJobRequestWorkloadsRequestsGetApiResponse,
      ListJobRequestWorkloadsRequestsGetApiArg
    >({
      query: () => ({ url: `/admin/workloads/requests` }),
    }),
    setJobRequestStatusWorkloadsRequestsRequestIdPatch: build.mutation<
      SetJobRequestStatusWorkloadsRequestsRequestIdPatchApiResponse,
      SetJobRequestStatusWorkloadsRequestsRequestIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/workloads/requests/${queryArg.requestId}`,
        method: "PATCH",
        params: { state: queryArg.state },
      }),
    }),
    createJobForRequestWorkloadsRequestsRequestIdJobsPost: build.mutation<
      CreateJobForRequestWorkloadsRequestsRequestIdJobsPostApiResponse,
      CreateJobForRequestWorkloadsRequestsRequestIdJobsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/workloads/requests/${queryArg.requestId}/jobs`,
        method: "POST",
      }),
    }),
    listJobsWorkloadsRequestsRequestIdJobsGet: build.query<
      ListJobsWorkloadsRequestsRequestIdJobsGetApiResponse,
      ListJobsWorkloadsRequestsRequestIdJobsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/workloads/requests/${queryArg.requestId}/jobs`,
      }),
    }),
    getJobWorkloadsRequestsRequestIdJobsJobIdGet: build.query<
      GetJobWorkloadsRequestsRequestIdJobsJobIdGetApiResponse,
      GetJobWorkloadsRequestsRequestIdJobsJobIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/workloads/requests/${queryArg.requestId}/jobs/${queryArg.jobId}`,
      }),
    }),
    setJobStatusWorkloadsRequestsRequestIdJobsJobIdPatch: build.mutation<
      SetJobStatusWorkloadsRequestsRequestIdJobsJobIdPatchApiResponse,
      SetJobStatusWorkloadsRequestsRequestIdJobsJobIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/admin/workloads/requests/${queryArg.requestId}/jobs/${queryArg.jobId}`,
        method: "PATCH",
        params: { state: queryArg.state },
      }),
    }),
    pingPingGet: build.query<PingPingGetApiResponse, PingPingGetApiArg>({
      query: () => ({ url: `/monitoring/ping` }),
    }),
    getVersionVersionGet: build.query<
      GetVersionVersionGetApiResponse,
      GetVersionVersionGetApiArg
    >({
      query: () => ({ url: `/monitoring/version` }),
    }),
    createCollectionCollectionsPost: build.mutation<
      CreateCollectionCollectionsPostApiResponse,
      CreateCollectionCollectionsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections`,
        method: "POST",
        body: queryArg.collectionDetails,
      }),
    }),
    getCollectionsCollectionsGet: build.query<
      GetCollectionsCollectionsGetApiResponse,
      GetCollectionsCollectionsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections`,
        params: { search: queryArg.search },
      }),
    }),
    deleteCollectionsCollectionsDelete: build.mutation<
      DeleteCollectionsCollectionsDeleteApiResponse,
      DeleteCollectionsCollectionsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections`,
        method: "DELETE",
        params: { collectionIds: queryArg.collectionIds },
      }),
    }),
    updateCollectionDetailsCollectionsCollectionIdPatch: build.mutation<
      UpdateCollectionDetailsCollectionsCollectionIdPatchApiResponse,
      UpdateCollectionDetailsCollectionsCollectionIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}`,
        method: "PATCH",
        body: queryArg.payload,
      }),
    }),
    createLandCoverClassesCollectionsCollectionIdLandCoverClassesPost:
      build.mutation<
        CreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostApiResponse,
        CreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/groundTruth/collections/${queryArg.collectionId}/landCoverClasses`,
          method: "POST",
          body: queryArg.landCoverClasses,
        }),
      }),
    listLandCoverClassesCollectionsCollectionIdLandCoverClassesGet: build.query<
      ListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetApiResponse,
      ListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/landCoverClasses`,
      }),
    }),
    uploadGtForPlotsCollectionsCollectionIdPlotsPost: build.mutation<
      UploadGtForPlotsCollectionsCollectionIdPlotsPostApiResponse,
      UploadGtForPlotsCollectionsCollectionIdPlotsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/plots`,
        method: "POST",
        body: queryArg.featureCollectionPlotGroundTruthInput,
      }),
    }),
    updateGtForPlotsCollectionsCollectionIdPlotsPatch: build.mutation<
      UpdateGtForPlotsCollectionsCollectionIdPlotsPatchApiResponse,
      UpdateGtForPlotsCollectionsCollectionIdPlotsPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/plots`,
        method: "PATCH",
        body: queryArg.featureCollectionPlotGroundTruthInput,
      }),
    }),
    getPlotsForCollectionsCollectionsCollectionIdPlotsGet: build.query<
      GetPlotsForCollectionsCollectionsCollectionIdPlotsGetApiResponse,
      GetPlotsForCollectionsCollectionsCollectionIdPlotsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/plots`,
      }),
    }),
    deletePlotsCollectionsCollectionIdPlotsDelete: build.mutation<
      DeletePlotsCollectionsCollectionIdPlotsDeleteApiResponse,
      DeletePlotsCollectionsCollectionIdPlotsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/plots`,
        method: "DELETE",
        params: { plotIds: queryArg.plotIds },
      }),
    }),
    uploadGtWithFilesCollectionsCollectionIdFilesPost: build.mutation<
      UploadGtWithFilesCollectionsCollectionIdFilesPostApiResponse,
      UploadGtWithFilesCollectionsCollectionIdFilesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/files`,
        method: "POST",
        body: queryArg.bodyUploadGtWithFilesCollectionsCollectionIdFilesPost,
      }),
    }),
    listFilesCollectionsCollectionIdFilesGet: build.query<
      ListFilesCollectionsCollectionIdFilesGetApiResponse,
      ListFilesCollectionsCollectionIdFilesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/files`,
      }),
    }),
    getPlotByIdCollectionsCollectionIdPlotsPlotIdGet: build.query<
      GetPlotByIdCollectionsCollectionIdPlotsPlotIdGetApiResponse,
      GetPlotByIdCollectionsCollectionIdPlotsPlotIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/groundTruth/collections/${queryArg.collectionId}/plots/${queryArg.plotId}`,
      }),
    }),
    getProjectReferencesReferencesGet: build.query<
      GetProjectReferencesReferencesGetApiResponse,
      GetProjectReferencesReferencesGetApiArg
    >({
      query: () => ({ url: `/projects/references` }),
    }),
    createProjectPost: build.mutation<
      CreateProjectPostApiResponse,
      CreateProjectPostApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/`,
        method: "POST",
        body: queryArg.projectCreationPayload,
      }),
    }),
    getProjectsGet: build.query<
      GetProjectsGetApiResponse,
      GetProjectsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/`,
        params: { search: queryArg.search },
      }),
    }),
    deleteCustomerProjectProjectIdDelete: build.mutation<
      DeleteCustomerProjectProjectIdDeleteApiResponse,
      DeleteCustomerProjectProjectIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}`,
        method: "DELETE",
      }),
    }),
    patchCustomerProjectProjectIdPatch: build.mutation<
      PatchCustomerProjectProjectIdPatchApiResponse,
      PatchCustomerProjectProjectIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}`,
        method: "PATCH",
        params: { confirm: queryArg.confirm, name: queryArg.name },
      }),
    }),
    getProjectProjectIdGet: build.query<
      GetProjectProjectIdGetApiResponse,
      GetProjectProjectIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/projects/${queryArg.projectId}` }),
    }),
    createPolygonProjectIdPolygonsPost: build.mutation<
      CreatePolygonProjectIdPolygonsPostApiResponse,
      CreatePolygonProjectIdPolygonsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/polygons`,
        method: "POST",
        body: queryArg.bodyCreatePolygonProjectIdPolygonsPost,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    deletePolygonProjectIdPolygonsDelete: build.mutation<
      DeletePolygonProjectIdPolygonsDeleteApiResponse,
      DeletePolygonProjectIdPolygonsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/polygons`,
        method: "DELETE",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { fileId: queryArg.fileId },
      }),
    }),
    getPolygonsForProjectProjectIdPolygonsGet: build.query<
      GetPolygonsForProjectProjectIdPolygonsGetApiResponse,
      GetPolygonsForProjectProjectIdPolygonsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/polygons`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { outputFormat: queryArg.outputFormat },
      }),
    }),
    getAggregationLevelForProjectProjectIdAggregationLevelsGet: build.query<
      GetAggregationLevelForProjectProjectIdAggregationLevelsGetApiResponse,
      GetAggregationLevelForProjectProjectIdAggregationLevelsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/aggregationLevels`,
      }),
    }),
    getAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGet:
      build.query<
        GetAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGetApiResponse,
        GetAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/aggregationLevels/${queryArg.level}`,
        }),
      }),
    createForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPost:
      build.mutation<
        CreateForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPostApiResponse,
        CreateForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPostApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/configurations/forestCoverDefinition`,
          method: "POST",
          body: queryArg.forestCoverDefinitionConfig,
        }),
      }),
    createAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPost:
      build.mutation<
        CreateAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPostApiResponse,
        CreateAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/configurations/allometricRelationships`,
          method: "POST",
          body: queryArg.allometricRelationshipsConfig,
        }),
      }),
    createRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPost:
      build.mutation<
        CreateRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPostApiResponse,
        CreateRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPostApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/configurations/recalibration`,
          method: "POST",
          body: queryArg.recalibrationConfigPayload,
        }),
      }),
    getConfigurationProjectIdConfigurationsGet: build.query<
      GetConfigurationProjectIdConfigurationsGetApiResponse,
      GetConfigurationProjectIdConfigurationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/configurations`,
        params: { isActive: queryArg.isActive, runId: queryArg.runId },
      }),
    }),
    uploadGroundTruthsProjectIdFieldMeasurementsPost: build.mutation<
      UploadGroundTruthsProjectIdFieldMeasurementsPostApiResponse,
      UploadGroundTruthsProjectIdFieldMeasurementsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/fieldMeasurements`,
        method: "POST",
        body: queryArg.bodyUploadGroundTruthsProjectIdFieldMeasurementsPost,
      }),
    }),
    getFieldMeasurementListProjectIdFieldMeasurementsGet: build.query<
      GetFieldMeasurementListProjectIdFieldMeasurementsGetApiResponse,
      GetFieldMeasurementListProjectIdFieldMeasurementsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/fieldMeasurements`,
      }),
    }),
    createDataRequestProjectIdRequestsPost: build.mutation<
      CreateDataRequestProjectIdRequestsPostApiResponse,
      CreateDataRequestProjectIdRequestsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/requests`,
        method: "POST",
        params: {
          requestedMeasurementDate: queryArg.requestedMeasurementDate,
          product: queryArg.product,
        },
      }),
    }),
    getRequestsProjectIdRequestsGet: build.query<
      GetRequestsProjectIdRequestsGetApiResponse,
      GetRequestsProjectIdRequestsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/requests`,
      }),
    }),
    getDataRequestProjectIdRequestsRequestIdGet: build.query<
      GetDataRequestProjectIdRequestsRequestIdGetApiResponse,
      GetDataRequestProjectIdRequestsRequestIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}`,
      }),
    }),
    getProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGet: build.query<
      GetProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGetApiResponse,
      GetProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/evolution/${queryArg.indicators}`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: {
          aggregationLevelFilters: queryArg.aggregationLevelFilters,
          aggregationLevel: queryArg.aggregationLevel,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
        },
      }),
    }),
    getProjectTsVariationIndicatorsProjectIdVariationIndicatorsGet: build.query<
      GetProjectTsVariationIndicatorsProjectIdVariationIndicatorsGetApiResponse,
      GetProjectTsVariationIndicatorsProjectIdVariationIndicatorsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/variation/${queryArg.indicators}`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: {
          aggregationLevelFilters: queryArg.aggregationLevelFilters,
          aggregationLevel: queryArg.aggregationLevel,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
        },
      }),
    }),
    getProjectTsChangeIndicatorsProjectIdChangeIndicatorsGet: build.query<
      GetProjectTsChangeIndicatorsProjectIdChangeIndicatorsGetApiResponse,
      GetProjectTsChangeIndicatorsProjectIdChangeIndicatorsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/projects/${queryArg.projectId}/change/${queryArg.indicators}`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: {
          aggregationLevel: queryArg.aggregationLevel,
          aggregationLevelFilters: queryArg.aggregationLevelFilters,
          fromDate: queryArg.fromDate,
          toDate: queryArg.toDate,
        },
      }),
    }),
    getSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGet:
      build.query<
        GetSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGetApiResponse,
        GetSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/metrics`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: { runId: queryArg.runId },
        }),
      }),
    getProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGet:
      build.query<
        GetProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGetApiResponse,
        GetProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/metrics/${queryArg.indicators}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: {
            aggregationLevel: queryArg.aggregationLevel,
            runId: queryArg.runId,
          },
        }),
      }),
    getStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGet:
      build.query<
        GetStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGetApiResponse,
        GetStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/geoMetrics/${queryArg.indicator}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: { runId: queryArg.runId },
        }),
      }),
    getChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGet:
      build.query<
        GetChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGetApiResponse,
        GetChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/geoChange/${queryArg.indicator}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: { compareToRequestId: queryArg.compareToRequestId },
        }),
      }),
    getAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGet:
      build.query<
        GetAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGetApiResponse,
        GetAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/gisFiles`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: { runId: queryArg.runId },
        }),
      }),
    downloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGet:
      build.query<
        DownloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGetApiResponse,
        DownloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/gisFiles/${queryArg.gisFileName}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: {
            outputFormat: queryArg.outputFormat,
            runId: queryArg.runId,
          },
        }),
      }),
    getVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGet:
      build.query<
        GetVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGetApiResponse,
        GetVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/variationGisFilesByRequest`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    getVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGet:
      build.query<
        GetVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGetApiResponse,
        GetVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/variationGisFiles`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    downloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGet:
      build.query<
        DownloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGetApiResponse,
        DownloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/variationGisFilesByRequest/${queryArg.variationGisFileName}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: {
            compareToRequestId: queryArg.compareToRequestId,
            outputFormat: queryArg.outputFormat,
            runId: queryArg.runId,
          },
        }),
      }),
    downloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGet:
      build.query<
        DownloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGetApiResponse,
        DownloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGetApiArg
      >({
        query: (queryArg) => ({
          url: `/projects/${queryArg.projectId}/requests/${queryArg.requestId}/variationGisFiles/${queryArg.variationGisFileName}`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
          params: {
            compareToRequestId: queryArg.compareToRequestId,
            outputFormat: queryArg.outputFormat,
            runId: queryArg.runId,
          },
        }),
      }),
    registerRegisterPost: build.mutation<
      RegisterRegisterPostApiResponse,
      RegisterRegisterPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/register`,
        method: "POST",
        body: queryArg.bodyRegisterRegisterPost,
      }),
    }),
    readUsersMeMeGet: build.query<
      ReadUsersMeMeGetApiResponse,
      ReadUsersMeMeGetApiArg
    >({
      query: () => ({ url: `/users/me` }),
    }),
    activateAccountConfirmGet: build.query<
      ActivateAccountConfirmGetApiResponse,
      ActivateAccountConfirmGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/confirm`,
        params: { token: queryArg.token, email: queryArg.email },
      }),
    }),
    activateAccountConfirmOptions: build.mutation<
      ActivateAccountConfirmOptionsApiResponse,
      ActivateAccountConfirmOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/confirm`,
        method: "OPTIONS",
        params: { token: queryArg.token, email: queryArg.email },
      }),
    }),
    loginLoginPost: build.mutation<
      LoginLoginPostApiResponse,
      LoginLoginPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/login`,
        method: "POST",
        body: queryArg.bodyLoginLoginPost,
      }),
    }),
    logoutLogoutPut: build.mutation<
      LogoutLogoutPutApiResponse,
      LogoutLogoutPutApiArg
    >({
      query: () => ({ url: `/users/logout`, method: "PUT" }),
    }),
    requestForgotPasswordTokenPasswordForgetPost: build.mutation<
      RequestForgotPasswordTokenPasswordForgetPostApiResponse,
      RequestForgotPasswordTokenPasswordForgetPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/password/forget`,
        method: "POST",
        params: { email: queryArg.email },
      }),
    }),
    resetPasswordPasswordPatch: build.mutation<
      ResetPasswordPasswordPatchApiResponse,
      ResetPasswordPasswordPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/password`,
        method: "PATCH",
        body: queryArg.bodyResetPasswordPasswordPatch,
        params: { token: queryArg.token },
      }),
    }),
    createApplicationForUserApplicationsPost: build.mutation<
      CreateApplicationForUserApplicationsPostApiResponse,
      CreateApplicationForUserApplicationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/applications`,
        method: "POST",
        body: queryArg.applicationCreationPayload,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getApplicationsForUserApplicationsGet: build.query<
      GetApplicationsForUserApplicationsGetApiResponse,
      GetApplicationsForUserApplicationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/applications`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getApplicationForClientIdApplicationsClientIdGet: build.query<
      GetApplicationForClientIdApplicationsClientIdGetApiResponse,
      GetApplicationForClientIdApplicationsClientIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/applications/${queryArg.clientId}`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    deleteApplicationByClientIdApplicationsClientIdDelete: build.mutation<
      DeleteApplicationByClientIdApplicationsClientIdDeleteApiResponse,
      DeleteApplicationByClientIdApplicationsClientIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/users/applications/${queryArg.clientId}`,
        method: "DELETE",
      }),
    }),
    updateApplicationByClientIdApplicationsClientIdPatch: build.mutation<
      UpdateApplicationByClientIdApplicationsClientIdPatchApiResponse,
      UpdateApplicationByClientIdApplicationsClientIdPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/applications/${queryArg.clientId}`,
        method: "PATCH",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { expiresAt: queryArg.expiresAt, newToken: queryArg.newToken },
      }),
    }),
    inviteUserToAppInviteesPost: build.mutation<
      InviteUserToAppInviteesPostApiResponse,
      InviteUserToAppInviteesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/invitees`,
        method: "POST",
        body: queryArg.inviteePayload,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    inviteUserToAppInviteesOptions: build.mutation<
      InviteUserToAppInviteesOptionsApiResponse,
      InviteUserToAppInviteesOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/invitees`,
        method: "OPTIONS",
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getOrganizationsOrganizationsGet: build.query<
      GetOrganizationsOrganizationsGetApiResponse,
      GetOrganizationsOrganizationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    createOrganizationOrganizationsOptions: build.mutation<
      CreateOrganizationOrganizationsOptionsApiResponse,
      CreateOrganizationOrganizationsOptionsApiArg
    >({
      query: () => ({ url: `/users/organizations`, method: "OPTIONS" }),
    }),
    createOrganizationOrganizationsPost: build.mutation<
      CreateOrganizationOrganizationsPostApiResponse,
      CreateOrganizationOrganizationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations`,
        method: "POST",
        body: queryArg.organizationPayload,
      }),
    }),
    getOrganizationByIdOrganizationsOrganizationIdGet: build.query<
      GetOrganizationByIdOrganizationsOrganizationIdGetApiResponse,
      GetOrganizationByIdOrganizationsOrganizationIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getOrganizationByIdOrganizationsOrganizationIdOptions: build.mutation<
      GetOrganizationByIdOrganizationsOrganizationIdOptionsApiResponse,
      GetOrganizationByIdOrganizationsOrganizationIdOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}`,
        method: "OPTIONS",
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getMembersByOrganizationIdOrganizationsOrganizationIdMembersGet:
      build.query<
        GetMembersByOrganizationIdOrganizationsOrganizationIdMembersGetApiResponse,
        GetMembersByOrganizationIdOrganizationsOrganizationIdMembersGetApiArg
      >({
        query: (queryArg) => ({
          url: `/users/organizations/${queryArg.organizationId}/members`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    deleteMemberOrganizationsOrganizationIdMembersOptions: build.mutation<
      DeleteMemberOrganizationsOrganizationIdMembersOptionsApiResponse,
      DeleteMemberOrganizationsOrganizationIdMembersOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}/members`,
        method: "OPTIONS",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { email: queryArg.email },
      }),
    }),
    deleteMemberOrganizationsOrganizationIdMembersDelete: build.mutation<
      DeleteMemberOrganizationsOrganizationIdMembersDeleteApiResponse,
      DeleteMemberOrganizationsOrganizationIdMembersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}/members`,
        method: "DELETE",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { email: queryArg.email },
      }),
    }),
    getRolesByOrganizationIdOrganizationsOrganizationIdRolesGet: build.query<
      GetRolesByOrganizationIdOrganizationsOrganizationIdRolesGetApiResponse,
      GetRolesByOrganizationIdOrganizationsOrganizationIdRolesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}/roles`,
        headers: { "accept-version": queryArg["accept-version"] || '' },
      }),
    }),
    getRolesByOrganizationIdOrganizationsOrganizationIdRolesOptions:
      build.mutation<
        GetRolesByOrganizationIdOrganizationsOrganizationIdRolesOptionsApiResponse,
        GetRolesByOrganizationIdOrganizationsOrganizationIdRolesOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/organizations/${queryArg.organizationId}/roles`,
          method: "OPTIONS",
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    getInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGet:
      build.query<
        GetInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGetApiResponse,
        GetInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGetApiArg
      >({
        query: (queryArg) => ({
          url: `/users/organizations/${queryArg.organizationId}/invitees`,
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    inviteUserToOrganizationOrganizationsOrganizationIdInviteesOptions:
      build.mutation<
        InviteUserToOrganizationOrganizationsOrganizationIdInviteesOptionsApiResponse,
        InviteUserToOrganizationOrganizationsOrganizationIdInviteesOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/organizations/${queryArg.organizationId}/invitees`,
          method: "OPTIONS",
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    inviteUserToOrganizationOrganizationsOrganizationIdInviteesPost:
      build.mutation<
        InviteUserToOrganizationOrganizationsOrganizationIdInviteesPostApiResponse,
        InviteUserToOrganizationOrganizationsOrganizationIdInviteesPostApiArg
      >({
        query: (queryArg) => ({
          url: `/users/organizations/${queryArg.organizationId}/invitees`,
          method: "POST",
          body: queryArg.inviteePayload,
          headers: { "accept-version": queryArg["accept-version"] || '' },
        }),
      }),
    acceptInvitationOrganizationsOrganizationIdAcceptPatch: build.mutation<
      AcceptInvitationOrganizationsOrganizationIdAcceptPatchApiResponse,
      AcceptInvitationOrganizationsOrganizationIdAcceptPatchApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}/accept`,
        method: "PATCH",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { invitee_uuid: queryArg.inviteeUuid },
      }),
    }),
    acceptInvitationOrganizationsOrganizationIdAcceptOptions: build.mutation<
      AcceptInvitationOrganizationsOrganizationIdAcceptOptionsApiResponse,
      AcceptInvitationOrganizationsOrganizationIdAcceptOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/organizations/${queryArg.organizationId}/accept`,
        method: "OPTIONS",
        headers: { "accept-version": queryArg["accept-version"] || '' },
        params: { invitee_uuid: queryArg.inviteeUuid },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as RawApi };
export type CreateNewRunProjectsProjectIdRunsPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateNewRunProjectsProjectIdRunsPostApiArg = {
  projectId: number;
  requestedMeasurementDate?: string;
  methodology?: string;
  priority?: number | null;
};
export type SetProjectDetailsProjectsProjectIdPatchApiResponse =
  /** status 200 Successful Response */ any;
export type SetProjectDetailsProjectsProjectIdPatchApiArg = {
  projectId: number;
  state?: string | null;
  owner?: number | null;
};
export type SetRunStatusProjectsProjectIdRunsRunIdPatchApiResponse =
  /** status 200 Successful Response */ any;
export type SetRunStatusProjectsProjectIdRunsRunIdPatchApiArg = {
  projectId: number;
  runId: number;
  state: string;
};
export type ListJobRequestWorkloadsRequestsGetApiResponse =
  /** status 200 Successful Response */ JobRequestsResponse;
export type ListJobRequestWorkloadsRequestsGetApiArg = void;
export type SetJobRequestStatusWorkloadsRequestsRequestIdPatchApiResponse =
  /** status 202 Successful Response */ any;
export type SetJobRequestStatusWorkloadsRequestsRequestIdPatchApiArg = {
  requestId: string;
  state: DataRequestStatusEnum;
};
export type CreateJobForRequestWorkloadsRequestsRequestIdJobsPostApiResponse =
  /** status 201 Successful Response */ JobCreationResponse;
export type CreateJobForRequestWorkloadsRequestsRequestIdJobsPostApiArg = {
  requestId: string;
};
export type ListJobsWorkloadsRequestsRequestIdJobsGetApiResponse =
  /** status 200 Successful Response */ JobMetaResponse;
export type ListJobsWorkloadsRequestsRequestIdJobsGetApiArg = {
  requestId: string;
};
export type GetJobWorkloadsRequestsRequestIdJobsJobIdGetApiResponse =
  /** status 200 Successful Response */ JobDetailsResponse;
export type GetJobWorkloadsRequestsRequestIdJobsJobIdGetApiArg = {
  requestId: string;
  jobId: string;
};
export type SetJobStatusWorkloadsRequestsRequestIdJobsJobIdPatchApiResponse =
  /** status 202 Successful Response */ any;
export type SetJobStatusWorkloadsRequestsRequestIdJobsJobIdPatchApiArg = {
  requestId: string;
  jobId: string;
  state: RunStatusEnum;
};
export type PingPingGetApiResponse = /** status 200 Successful Response */ Ping;
export type PingPingGetApiArg = void;
export type GetVersionVersionGetApiResponse =
  /** status 200 Successful Response */ Version;
export type GetVersionVersionGetApiArg = void;
export type CreateCollectionCollectionsPostApiResponse =
  /** status 201 Successful Response */ CollectionCreationResponse;
export type CreateCollectionCollectionsPostApiArg = {
  collectionDetails: CollectionCreationRequest[];
};
export type GetCollectionsCollectionsGetApiResponse =
  /** status 200 Successful Response */ CollectionsResponse;
export type GetCollectionsCollectionsGetApiArg = {
  search?: string | null;
};
export type DeleteCollectionsCollectionsDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteCollectionsCollectionsDeleteApiArg = {
  collectionIds: string[];
};
export type UpdateCollectionDetailsCollectionsCollectionIdPatchApiResponse =
  /** status 202 Successful Response */ any;
export type UpdateCollectionDetailsCollectionsCollectionIdPatchApiArg = {
  collectionId: string;
  payload: EditCollectionPayload | null;
};
export type CreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostApiResponse =
  /** status 201 Successful Response */ LandCoverClassCreationResponse;
export type CreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostApiArg =
  {
    collectionId: string;
    landCoverClasses: LandCoverClassCreationRequest[];
  };
export type ListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetApiResponse =
  /** status 200 Successful Response */ LandCoverClassesResponse;
export type ListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetApiArg =
  {
    collectionId: string;
  };
export type UploadGtForPlotsCollectionsCollectionIdPlotsPostApiResponse =
  /** status 201 Successful Response */ PlotCreationResponse;
export type UploadGtForPlotsCollectionsCollectionIdPlotsPostApiArg = {
  collectionId: string;
  featureCollectionPlotGroundTruthInput: FeatureCollectionPlotGroundTruth;
};
export type UpdateGtForPlotsCollectionsCollectionIdPlotsPatchApiResponse =
  /** status 202 Successful Response */ PlotUpdateResponse;
export type UpdateGtForPlotsCollectionsCollectionIdPlotsPatchApiArg = {
  collectionId: string;
  featureCollectionPlotGroundTruthInput: FeatureCollectionPlotGroundTruth;
};
export type GetPlotsForCollectionsCollectionsCollectionIdPlotsGetApiResponse =
  /** status 200 Successful Response */ PlotsResponse;
export type GetPlotsForCollectionsCollectionsCollectionIdPlotsGetApiArg = {
  collectionId: string;
};
export type DeletePlotsCollectionsCollectionIdPlotsDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePlotsCollectionsCollectionIdPlotsDeleteApiArg = {
  collectionId: string;
  plotIds: string[];
};
export type UploadGtWithFilesCollectionsCollectionIdFilesPostApiResponse =
  /** status 201 Successful Response */ FileCreatedResponse;
export type UploadGtWithFilesCollectionsCollectionIdFilesPostApiArg = {
  collectionId: string;
  bodyUploadGtWithFilesCollectionsCollectionIdFilesPost: BodyUploadGtWithFilesCollectionsCollectionIdFilesPost;
};
export type ListFilesCollectionsCollectionIdFilesGetApiResponse =
  /** status 200 Successful Response */ FilesResponse;
export type ListFilesCollectionsCollectionIdFilesGetApiArg = {
  collectionId: string;
};
export type GetPlotByIdCollectionsCollectionIdPlotsPlotIdGetApiResponse =
  /** status 200 Successful Response */ PlotResponse;
export type GetPlotByIdCollectionsCollectionIdPlotsPlotIdGetApiArg = {
  collectionId: string;
  plotId: string;
};
export type GetProjectReferencesReferencesGetApiResponse =
  /** status 200 Successful Response */ ProjectReferences;
export type GetProjectReferencesReferencesGetApiArg = void;
export type CreateProjectPostApiResponse =
  /** status 201 Successful Response */ ProjectCreationResponse;
export type CreateProjectPostApiArg = {
  projectCreationPayload: ProjectCreationPayload;
};
export type GetProjectsGetApiResponse =
  /** status 200 Successful Response */ ProjectsResponse;
export type GetProjectsGetApiArg = {
  search?: string | null;
};
export type DeleteCustomerProjectProjectIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteCustomerProjectProjectIdDeleteApiArg = {
  projectId: number;
};
export type PatchCustomerProjectProjectIdPatchApiResponse =
  /** status 200 Successful Response */ any;
export type PatchCustomerProjectProjectIdPatchApiArg = {
  projectId: number;
  confirm?: boolean | null;
  name?: string | null;
};
export type GetProjectProjectIdGetApiResponse =
  /** status 200 Successful Response */ ProjectDetailsResponse;
export type GetProjectProjectIdGetApiArg = {
  projectId: number;
};
export type CreatePolygonProjectIdPolygonsPostApiResponse =
  /** status 201 the polygon has been saved */ PolygonCreationResponse;
export type CreatePolygonProjectIdPolygonsPostApiArg = {
  projectId: number;
  /** the API version to use, only v1 supported at the moment */
  "accept-version"?: string | null;
  bodyCreatePolygonProjectIdPolygonsPost: BodyCreatePolygonProjectIdPolygonsPost;
};
export type DeletePolygonProjectIdPolygonsDeleteApiResponse =
  /** status 200 the polygon has been deleted */ any;
export type DeletePolygonProjectIdPolygonsDeleteApiArg = {
  projectId: number;
  fileId: string;
  /** the API */
  "accept-version"?: string | null;
};
export type GetPolygonsForProjectProjectIdPolygonsGetApiResponse =
  /** status 200 Successful Response */ PolygonResponse;
export type GetPolygonsForProjectProjectIdPolygonsGetApiArg = {
  projectId: number;
  outputFormat?: OutputModes;
  "accept-version"?: string | null;
};
export type GetAggregationLevelForProjectProjectIdAggregationLevelsGetApiResponse =
  /** status 200 Successful Response */ AggregationLevelsResponse;
export type GetAggregationLevelForProjectProjectIdAggregationLevelsGetApiArg = {
  projectId: number;
};
export type GetAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGetApiResponse =
  /** status 200 Successful Response */ {
    [key: string]: string[];
  };
export type GetAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGetApiArg =
  {
    projectId: number;
    level: string;
  };
export type CreateForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPostApiArg =
  {
    projectId: number;
    forestCoverDefinitionConfig: ForestCoverDefinitionConfig;
  };
export type CreateAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPostApiArg =
  {
    projectId: number;
    allometricRelationshipsConfig: AllometricRelationshipsConfig;
  };
export type CreateRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPostApiArg =
  {
    projectId: number;
    recalibrationConfigPayload: RecalibrationConfigPayload;
  };
export type GetConfigurationProjectIdConfigurationsGetApiResponse =
  /** status 200 Successful Response */ ProjectConfigurationsPayload;
export type GetConfigurationProjectIdConfigurationsGetApiArg = {
  projectId: number;
  isActive?: boolean;
  /** can only be used by users with high permissions level. */
  runId?: number | null;
};
export type UploadGroundTruthsProjectIdFieldMeasurementsPostApiResponse =
  /** status 201 the Field measurement data has been saved */ FieldMeasurementUploadResponse;
export type UploadGroundTruthsProjectIdFieldMeasurementsPostApiArg = {
  projectId: number;
  bodyUploadGroundTruthsProjectIdFieldMeasurementsPost: BodyUploadGroundTruthsProjectIdFieldMeasurementsPost;
};
export type GetFieldMeasurementListProjectIdFieldMeasurementsGetApiResponse =
  /** status 200 Successful Response */ FieldMeasurementListResponse;
export type GetFieldMeasurementListProjectIdFieldMeasurementsGetApiArg = {
  projectId: number;
};
export type CreateDataRequestProjectIdRequestsPostApiResponse =
  /** status 201 Successful Response */ any;
export type CreateDataRequestProjectIdRequestsPostApiArg = {
  projectId: number;
  /** To optimize the precision of the output, our models integrate multi-temporal data, leveraging images taken in a one-year window centered around the requestedMeasurementDate — six months before and six months after it.  We suggest that you use the first of July to request data on a given calendar year  Example: 2022-07-01 to cover 2022. */
  requestedMeasurementDate?: string | null;
  /** Request a specific product. */
  product: ProductEnum;
};
export type GetRequestsProjectIdRequestsGetApiResponse =
  /** status 200 Successful Response */ DataRequestDetailsResponse;
export type GetRequestsProjectIdRequestsGetApiArg = {
  projectId: number;
};
export type GetDataRequestProjectIdRequestsRequestIdGetApiResponse =
  /** status 200 Successful Response */ DataRequestDetailResponse;
export type GetDataRequestProjectIdRequestsRequestIdGetApiArg = {
  projectId: number;
  requestId: string;
};
export type GetProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGetApiResponse =
  /** status 200 Successful Response */ TimeSeriesIndicatorDistributionWithUncertaintyUnionFloatIntNoneType;
export type GetProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGetApiArg =
  {
    projectId: number;
    indicators: string;
    aggregationLevelFilters?: string | null;
    aggregationLevel?: string;
    /** the lower bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
    fromDate?: string | null;
    /** the upper bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
    toDate?: string | null;
    "accept-version"?: string | null;
  };
export type GetProjectTsVariationIndicatorsProjectIdVariationIndicatorsGetApiResponse =
  /** status 200 Successful Response */ TimeSeriesIndicatorDistributionUnionFloatIntNoneType;
export type GetProjectTsVariationIndicatorsProjectIdVariationIndicatorsGetApiArg =
  {
    projectId: number;
    indicators: string;
    aggregationLevelFilters?: string | null;
    aggregationLevel?: string;
    /** the lower bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
    fromDate?: string | null;
    /** the upper bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
    toDate?: string | null;
    "accept-version"?: string | null;
  };
export type GetProjectTsChangeIndicatorsProjectIdChangeIndicatorsGetApiResponse =
  /** status 200 Successful Response */ TimeSeriesIndicatorDistributionChange;
export type GetProjectTsChangeIndicatorsProjectIdChangeIndicatorsGetApiArg = {
  projectId: any;
  indicators: string;
  aggregationLevel?: AggregationLevel;
  /** the aggregation value to filter on, can be a specific polygon reference or a value set for an aggregation level. */
  aggregationLevelFilters?: string | null;
  /** the lower bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
  fromDate?: string | null;
  /** the upper bound date to filter the timeseries on. Formatted as YYYY-MM-DD */
  toDate?: string | null;
  "accept-version"?: string | null;
};
export type GetSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGetApiResponse =
  /** status 200 Successful Response */ MetricResult;
export type GetSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** requesting a specific runId is only possible for users with high permission level. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type GetProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGetApiResponse =
  /** status 200 Successful Response */ Indicator;
export type GetProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** you can find the list of available indicators for a data request by querying [this endpoint](#tag/Requesting-analysis/operation/get_data_request__projectId__requests__requestId__get)Note that you can ask for one or multiple indicators by separating them with a comma, for instance: `forestCover` or `forestCover,canopyHeightMean` */
    indicators: string;
    aggregationLevel?: AggregationLevel;
    /** requesting a specific runId is only possible for users with high permission level. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type GetStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGetApiResponse =
  /** status 200 Successful Response */ FeatureCollectionStockFeature;
export type GetStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** you can find the list of available indicators for a data request by querying [this endpoint](#tag/Requesting-analysis/operation/get_data_request__projectId__requests__requestId__get) */
    indicator: string;
    /** requesting a specific runId is only possible for users with high permission level. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type GetChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGetApiResponse =
  /** status 200 Successful Response */ FeatureCollectionChangeFeature;
export type GetChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** you can find the list of available indicators for a data request by querying [this endpoint](#tag/Requesting-analysis/operation/get_data_request__projectId__requests__requestId__get) */
    indicator: string;
    /** requesting a specific runId is only possible for users with high permission level. */
    compareToRequestId?: string | null;
    "accept-version"?: string | null;
  };
export type GetAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGetApiResponse =
  /** status 200 Successful Response */ GisFiles;
export type GetAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGetApiArg =
  {
    projectId: number;
    requestId: string;
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type DownloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGetApiResponse =
  /** status 200 Successful Response */ Blob;
export type DownloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** GIS file name to download, without extension. Example : _'canopy_height_mean'_. */
    gisFileName: string;
    /** Only tif files are available. */
    outputFormat?: any;
    /** Only available to admins. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type GetVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGetApiResponse =
  /** status 200 Successful Response */ VariationGisFilesByRequest;
export type GetVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGetApiArg =
  {
    projectId: number;
    requestId: string;
    "accept-version"?: string | null;
  };
export type GetVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGetApiResponse =
  /** status 200 Successful Response */ VariationGisFiles;
export type GetVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGetApiArg =
  {
    projectId: number;
    requestId: string;
    "accept-version"?: string | null;
  };
export type DownloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGetApiResponse =
  /** status 200 Successful Response */ Blob;
export type DownloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** variation GIS file name to download, without extension. Example : _'canopy_height_mean'_. */
    variationGisFileName: string;
    /** the id of the data request to compare with. */
    compareToRequestId: string;
    /** only TIF files are available. */
    outputFormat?: any;
    /** Only available to admins. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type DownloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGetApiResponse =
  /** status 200 Successful Response */ Blob;
export type DownloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGetApiArg =
  {
    projectId: number;
    requestId: string;
    /** variation GIS file name to download, without extension. Example : _'canopy_height_mean'_. */
    variationGisFileName: string;
    /** the id of the data request to compare with. */
    compareToRequestId: string;
    /** only TIF files are available. */
    outputFormat?: any;
    /** Only available to admins. */
    runId?: number | null;
    "accept-version"?: string | null;
  };
export type RegisterRegisterPostApiResponse =
  /** status 201 Successful Response */ any;
export type RegisterRegisterPostApiArg = {
  bodyRegisterRegisterPost: BodyRegisterRegisterPost;
};
export type ReadUsersMeMeGetApiResponse =
  /** status 200 Successful Response */ AuthenticatedUser;
export type ReadUsersMeMeGetApiArg = void;
export type ActivateAccountConfirmGetApiResponse = unknown;
export type ActivateAccountConfirmGetApiArg = {
  token: string;
  email: string;
};
export type ActivateAccountConfirmOptionsApiResponse =
  /** status 200 Successful Response */ any;
export type ActivateAccountConfirmOptionsApiArg = {
  token: string;
  email: string;
};
export type LoginLoginPostApiResponse =
  /** status 200 Successful Response */ LoginResponse;
export type LoginLoginPostApiArg = {
  bodyLoginLoginPost: BodyLoginLoginPost;
};
export type LogoutLogoutPutApiResponse =
  /** status 200 Successful Response */ any;
export type LogoutLogoutPutApiArg = void;
export type RequestForgotPasswordTokenPasswordForgetPostApiResponse =
  /** status 201 Successful Response */ any;
export type RequestForgotPasswordTokenPasswordForgetPostApiArg = {
  email: string;
};
export type ResetPasswordPasswordPatchApiResponse =
  /** status 200 Successful Response */ any;
export type ResetPasswordPasswordPatchApiArg = {
  token: string;
  bodyResetPasswordPasswordPatch: BodyResetPasswordPasswordPatch;
};
export type CreateApplicationForUserApplicationsPostApiResponse =
  /** status 201 Successful Response */ ApplicationCredentialsResponse;
export type CreateApplicationForUserApplicationsPostApiArg = {
  "accept-version"?: string | null;
  applicationCreationPayload: ApplicationCreationPayload;
};
export type GetApplicationsForUserApplicationsGetApiResponse =
  /** status 200 Successful Response */ ApplicationsResponse | null;
export type GetApplicationsForUserApplicationsGetApiArg = {
  "accept-version"?: string | null;
};
export type GetApplicationForClientIdApplicationsClientIdGetApiResponse =
  /** status 200 Successful Response */ ApplicationResponse | null;
export type GetApplicationForClientIdApplicationsClientIdGetApiArg = {
  clientId: string;
  "accept-version"?: string | null;
};
export type DeleteApplicationByClientIdApplicationsClientIdDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteApplicationByClientIdApplicationsClientIdDeleteApiArg = {
  clientId: string;
};
export type UpdateApplicationByClientIdApplicationsClientIdPatchApiResponse =
  /** status 200 Successful Response */ any;
export type UpdateApplicationByClientIdApplicationsClientIdPatchApiArg = {
  clientId: string;
  expiresAt?: string | string | null;
  newToken?: boolean;
  "accept-version"?: string | null;
};
export type InviteUserToAppInviteesPostApiResponse =
  /** status 201 Successful Response */ any;
export type InviteUserToAppInviteesPostApiArg = {
  "accept-version"?: string | null;
  inviteePayload: InviteePayload;
};
export type InviteUserToAppInviteesOptionsApiResponse =
  /** status 200 Successful Response */ any;
export type InviteUserToAppInviteesOptionsApiArg = {
  "accept-version"?: string | null;
};
export type GetOrganizationsOrganizationsGetApiResponse =
  /** status 200 Successful Response */ OrganizationsResponse;
export type GetOrganizationsOrganizationsGetApiArg = {
  "accept-version"?: string | null;
};
export type CreateOrganizationOrganizationsOptionsApiResponse =
  /** status 200 Successful Response */ OrganizationCreationResponse;
export type CreateOrganizationOrganizationsOptionsApiArg = void;
export type CreateOrganizationOrganizationsPostApiResponse =
  /** status 201 Successful Response */ OrganizationCreationResponse;
export type CreateOrganizationOrganizationsPostApiArg = {
  organizationPayload: OrganizationPayload;
};
export type GetOrganizationByIdOrganizationsOrganizationIdGetApiResponse =
  /** status 200 Successful Response */ OrganizationDataResponse;
export type GetOrganizationByIdOrganizationsOrganizationIdGetApiArg = {
  organizationId: string;
  "accept-version"?: string | null;
};
export type GetOrganizationByIdOrganizationsOrganizationIdOptionsApiResponse =
  /** status 200 Successful Response */ OrganizationDataResponse;
export type GetOrganizationByIdOrganizationsOrganizationIdOptionsApiArg = {
  organizationId: string;
  "accept-version"?: string | null;
};
export type GetMembersByOrganizationIdOrganizationsOrganizationIdMembersGetApiResponse =
  /** status 200 Successful Response */ MembersResponse;
export type GetMembersByOrganizationIdOrganizationsOrganizationIdMembersGetApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
  };
export type DeleteMemberOrganizationsOrganizationIdMembersOptionsApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteMemberOrganizationsOrganizationIdMembersOptionsApiArg = {
  organizationId: string;
  email: string;
  "accept-version"?: string | null;
};
export type DeleteMemberOrganizationsOrganizationIdMembersDeleteApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteMemberOrganizationsOrganizationIdMembersDeleteApiArg = {
  organizationId: string;
  email: string;
  "accept-version"?: string | null;
};
export type GetRolesByOrganizationIdOrganizationsOrganizationIdRolesGetApiResponse =
  /** status 200 Successful Response */ RolesResponse;
export type GetRolesByOrganizationIdOrganizationsOrganizationIdRolesGetApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
  };
export type GetRolesByOrganizationIdOrganizationsOrganizationIdRolesOptionsApiResponse =
  /** status 200 Successful Response */ RolesResponse;
export type GetRolesByOrganizationIdOrganizationsOrganizationIdRolesOptionsApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
  };
export type GetInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGetApiResponse =
  /** status 200 Successful Response */ InviteesResponse;
export type GetInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGetApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
  };
export type InviteUserToOrganizationOrganizationsOrganizationIdInviteesOptionsApiResponse =
  /** status 200 Successful Response */ any;
export type InviteUserToOrganizationOrganizationsOrganizationIdInviteesOptionsApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
  };
export type InviteUserToOrganizationOrganizationsOrganizationIdInviteesPostApiResponse =
  /** status 201 Successful Response */ any;
export type InviteUserToOrganizationOrganizationsOrganizationIdInviteesPostApiArg =
  {
    organizationId: string;
    "accept-version"?: string | null;
    inviteePayload: InviteePayload;
  };
export type AcceptInvitationOrganizationsOrganizationIdAcceptPatchApiResponse =
  /** status 201 Successful Response */ any;
export type AcceptInvitationOrganizationsOrganizationIdAcceptPatchApiArg = {
  organizationId: string;
  inviteeUuid: string;
  "accept-version"?: string | null;
};
export type AcceptInvitationOrganizationsOrganizationIdAcceptOptionsApiResponse =
  /** status 200 Successful Response */ any;
export type AcceptInvitationOrganizationsOrganizationIdAcceptOptionsApiArg = {
  organizationId: string;
  inviteeUuid: string;
  "accept-version"?: string | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type PipelineTypeEnum = "project_init" | "housekeeping" | "calibration";
export type DataRequestStatusEnum =
  | "REQUESTED"
  | "REJECTED"
  | "CANCELLED"
  | "APPROVED"
  | "RUNNING"
  | "COMPLETED";
export type JobRequest = {
  jobRequestId: string;
  pipeline: string;
  pipelineType: PipelineTypeEnum;
  jobRequestStatus: DataRequestStatusEnum;
  configuration: object;
  owner?: string | null;
  createdAt: string;
};
export type JobRequestsResponse = {
  jobRequests: JobRequest[];
};
export type JobCreationResponse = {
  jobId: string;
};
export type RunStatusEnum =
  | "REQUESTED"
  | "APPROVED"
  | "RUNNING"
  | "READY"
  | "SHARED"
  | "ERROR"
  | "REJECTED"
  | "CANCELED"
  | "RESTARTED"
  | "QUEUED"
  | "PENDING"
  | "STUCK";
export type JobMeta = {
  jobId: string;
  pipeline: string;
  status: RunStatusEnum;
  createdAt: string;
  creator: string;
  owner?: string | null;
};
export type JobMetaResponse = {
  jobs: JobMeta[];
};
export type JobDetails = {
  jobId: string;
  jobRequestId: string;
  pipeline: string;
  status: RunStatusEnum;
  creator: string;
  createdAt: string;
  owner?: string | null;
  configuration?: object;
  context?: object;
};
export type JobDetailsResponse = {
  job: JobDetails;
};
export type Ping = {
  message: string;
};
export type Version = {
  version: string;
  startedAt: string;
};
export type CollectionCreated = {
  collectionId: string;
  label: string;
};
export type CollectionCreationResponse = {
  collections: CollectionCreated[];
};
export type CollectionCreationRequest = {
  label: string;
  organizationId: string;
  description?: string | null;
};
export type CollectionStatusEnum =
  | "DRAFT"
  | "SUBMITTED"
  | "PROCESSING"
  | "READY"
  | "DELETED";
export type CollectionDetails = {
  collectionId: string;
  label: string;
  description?: string | null;
  status: CollectionStatusEnum;
  createdAt: string;
  owner: string | null;
  plotCount?: number;
};
export type CollectionsResponse = {
  collections: CollectionDetails[];
};
export type EditCollectionPayload = {
  label?: string | null;
  description?: string | null;
  finalized?: boolean | null;
};
export type LandCoverClassCreated = {
  classId: string;
  label: string;
};
export type LandCoverClassCreationResponse = {
  classes: LandCoverClassCreated[];
};
export type LandCoverClassCreationRequest = {
  label: string;
  color?: string | null;
};
export type LandCoverClassDetail = {
  classId: string;
  label: string;
  color: string | null;
  owner: string | null;
};
export type LandCoverClassesResponse = {
  classes: LandCoverClassDetail[];
};
export type PlotCreated = {
  plotId: string;
  plotReference: string;
};
export type PlotCreationResponse = {
  plots: PlotCreated[];
};
export type PlotProperties = {
  plotReference: string;
  observationDate: string;
  plotId?: string | null;
  collectionId?: string | null;
  landCoverClassId?: string | null;
  canopyCover?: number | number | null;
  treeHeightMean?: number | number | null;
  canopyHeightMean?: number | number | null;
  agbValue?: number | number | null;
  soilOrganicCarbon?: number | number | null;
  comments?: string | null;
};
export type Geometry = {
  type: string;
  coordinates: any[];
};
export type PlotGroundTruth = {
  type: string;
  properties: PlotProperties;
  geometry: Geometry;
};
export type FeatureCollectionPlotGroundTruth = {
  type: string;
  features: PlotGroundTruth[];
};
export type PlotUpdated = {
  plotId: string;
};
export type PlotUpdateResponse = {
  plots: PlotUpdated[];
};
export type PlotMeta = {
  plotReference: string;
  observationDate: string;
  centroid?: Geometry | null;
  plotId?: string | null;
  landCoverClassId?: string | null;
  canopyCover?: number | number | null;
  treeHeightMean?: number | number | null;
  canopyHeightMean?: number | number | null;
  agbValue?: number | number | null;
  soilOrganicCarbon?: number | number | null;
  owner: string | null;
};
export type PlotsResponse = {
  plots: PlotMeta[];
};
export type FileCreatedResponse = {
  fileId?: string | null;
  fileName?: string | null;
};
export type BodyUploadGtWithFilesCollectionsCollectionIdFilesPost = {
  plots: Blob;
};
export type PlotFileStatusEnum =
  | "INITIALIZED"
  | "RECEIVED"
  | "COMPLETED"
  | "REJECTED";
export type FilesMeta = {
  fileId?: string | null;
  fileName?: string | null;
  status: PlotFileStatusEnum;
  createdAt: string;
  owner: string | null;
};
export type FilesResponse = {
  files: FilesMeta[];
};
export type FeatureCollectionPlotGroundTruth2 = {
  type: string;
  features: PlotGroundTruth[];
};
export type PlotResponse = {
  plot: FeatureCollectionPlotGroundTruth2;
};
export type ForestCoverDefinitionDetails = {
  /** Expressed in meters */
  minTreeHeight: number | number;
  /** Expressed in ha */
  minArea: number | number;
  /** Expressed in percentage */
  crownCoverPercentage: number | number;
};
export type ForestCoverDefinitionConfig = {
  /** You can retrieve available labels from `GET /projects/references` endpoint, or use `Customized` if you want to set your own forest cover definition. */
  label: string;
  details: ForestCoverDefinitionDetails;
  /** Only required if you are updating a `Customized` configuration. */
  configId?: number | null;
};
export type AllometricRelationshipsEnum =
  | "Carbon stock"
  | "Belowground biomass";
export type AllometricRelationshipsContent = {
  /** Use “AGB” for the aboveground biomass of the tree (in tons of DM) and “biomass” for the total biomass (belowground + aboveground) of the tree (in tons of DM). Use”**” for power. */
  formula: string;
};
export type AllometricRelationshipsPayload = {
  /** Choose the allometric relationship to customize. */
  label: AllometricRelationshipsEnum;
  details: AllometricRelationshipsContent;
};
export type ProjectReferences = {
  projectTypes: string[];
  forestCoverDefinition: ForestCoverDefinitionConfig[];
  allometricRelationships: AllometricRelationshipsPayload[];
};
export type ProjectCreationResponse = {
  projectId: number;
};
export type ProjectCreationPayload = {
  name: string;
  description?: string | null;
};
export type ProjectStatusEnum =
  | "CREATED"
  | "APPROVED"
  | "INITIALISED"
  | "MONITORING"
  | "TERMINATED"
  | "STANDBY"
  | "CANCELLED"
  | "DRAFT"
  | "DELETED";
export type ProjectResponse = {
  projectId?: number | null;
  customerId?: number | null;
  name: string;
  description?: string | null;
  country?: string | null;
  status?: ProjectStatusEnum | null;
  area?: number | null;
  polygonCount?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
  creator?: string | null;
};
export type ProjectsResponse = {
  projects: ProjectResponse[];
};
export type ProjectDetailsResponse = {
  projectId?: number | null;
  customerId?: number | null;
  name: string;
  description?: string | null;
  country?: string | null;
  status?: ProjectStatusEnum | null;
  area?: number | null;
  polygonCount?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: string | null;
  creator?: string | null;
  /** the list of metric we will provide for a project,such as `forest_cover`or `canopy_height`. This list is useful in you need to get results for one or several metrics */
  metrics?: string[] | null;
};
export type PolygonCreationResponse = {
  polygonFileId: string;
};
export type Error = {
  detail: string;
};
export type BodyCreatePolygonProjectIdPolygonsPost = {
  /** A list of WKT-formatted polygons, mandatory if you are not uploading a file. */
  geometry?: string[];
  /** The Coordinate Reference System, (also known as SRS) specified as an authority identifier (e.g., “EPSG:4326”) or a WKT string (4326), mandatory if you send a raw geometry data. */
  crs?: string;
  /** [optional] An array of identifiers for each of your polygon. Each identifier in the array must correspond to the polygon at the same position in the 'geometry' array. */
  customerReferencePolygon?: (string | number)[];
  /** [optional] An array for the first level of aggregation you would like to see in our visualisation features. Each aggregation level in the array must correspond to the polygon at the same position in the 'geometry' array. */
  customerAgregationLevel1?: (string | number)[];
  /** An array for the second level of aggregation you would like to see in our visualisation features. Each aggregation level in the array must correspond to the polygon at the same position in the 'geometry' array */
  customerAgregationLevel2?: (string | number)[];
  /** A valid GeoJSON file, adhering to [our guidelines](https://docs.google.com/document/d/1YS0DQ3--oSAJ8UCt0wMQhubDqHOXT8JdvmyD-nKFuOE/edit?usp=sharing).This option cannot be used simultaneously with uploading raw polygon data. */
  aoi?: Blob | null;
};
export type PolygonResponse = {
  projectId: number;
  geometry: object;
};
export type OutputModes = "httpResponse" | "jsonFile";
export type AggregationLevel = "polygons" | "level1" | "level2";
export type AggregationLevelsResponse = {
  aggregationLevels: AggregationLevel[];
};
export type AllometricRelationshipsConfig = {
  configId?: number | null;
  relationships: AllometricRelationshipsPayload[];
};
export type RecalibrationConfigPayload = {
  collections: string[];
};
export type RecalibrationCollection = {
  collection_id: string;
  label: string;
  plot_count: number;
  validated_on: string;
};
export type Recalibration = {
  collections?: RecalibrationCollection[];
};
export type ProjectConfigurationContent = {
  allometricRelationships: AllometricRelationshipsPayload[];
  forestCoverDefinition: ForestCoverDefinitionConfig;
  recalibration?: Recalibration;
};
export type ProjectConfigurationPayload = {
  projectId: number;
  configurationId?: number | null;
  content: ProjectConfigurationContent;
  createdBy?: number | null;
  isActive?: boolean | null;
  appliedFrom?: string | null;
  appliedUntil?: string | null;
};
export type ProjectConfigurationsPayload = {
  configurations: ProjectConfigurationPayload[];
};
export type FieldMeasurementUploadResponse = {
  fieldMeasurementFileId: string;
};
export type BodyUploadGroundTruthsProjectIdFieldMeasurementsPost = {
  /** A valid GeoJSON file */
  content: Blob;
};
export type FieldMeasurement = {
  fileId: string;
  fileName: string;
  createdAt: string;
  createdBy: string;
};
export type FieldMeasurementListResponse = {
  fieldMeasurements?: FieldMeasurement[];
};
export type ProductEnum = "screening_25m" | "standard_10m" | "standard_25m";
export type DataRequestStatus =
  | "REQUESTED"
  | "APPROVED"
  | "RUNNING"
  | "COMPLETED"
  | "CANCELED";
export type ProductTiers = "standard" | "screening";
export type Product = "Standard 25m" | "Standard 10m" | "Screening 25m";
export type RunDetailResponse = {
  runId: number;
  runStatus: RunStatusEnum;
  runDate?: string | null;
};
export type DataRequestDetailResponse = {
  requestId: string;
  projectId: number;
  requestMeasurementDate: string;
  status: DataRequestStatus;
  tiers: ProductTiers;
  product?: Product | null;
  comments?: string | null;
  /** the timestamp when the data request has been created */
  createdAt: string;
  /** the type of processing applied for this data request */
  methodology: string;
  /** which metrics will be available for this data request */
  metrics?: string[] | null;
  /** only useful for users with high permission level */
  runs?: RunDetailResponse[] | null;
  /** provide the list of CSV files available for download */
  downloads?: string[] | null;
  /** The configuration applied to the data request */
  configuration?: ProjectConfigurationContent | null;
  /** Indicates who has created the data request */
  createdBy?: string | null;
  /** Indicates who owns the data request. */
  owner?: string | null;
};
export type DataRequestDetailsResponse = {
  dataRequests: DataRequestDetailResponse[];
};
export type Pagination = {
  size?: number;
  current?: number;
};
export type Meta = {
  version?: string;
  requestTimestamp?: number;
  page?: Pagination | null;
};
export type TimeSeriesContext = {
  projectId: number;
  runId?: number | null;
  indicators: string[];
  aggregationLevel: string;
  aggregationLevelValues?: (string | number)[] | null;
  timeseries: string;
  timeseriesValues: (string | string)[];
};
export type DistributionWithUncertaintyUnionFloatIntNoneType = {
  aggregate: string | number | string;
  value?: number | number | null;
  confidenceLowerBound?: number | number | null;
  confidenceUpperBound?: number | number | null;
};
export type DetailedMetricDistributionWithUncertaintyUnionFloatIntNoneType = {
  average?: number | null;
  median?: number | null;
  range?: (number | number)[] | null;
  averageDistribution: DistributionWithUncertaintyUnionFloatIntNoneType[];
};
export type TimeSeriesIndicatorDistributionWithUncertaintyUnionFloatIntNoneType =
  {
    meta: Meta;
    context: TimeSeriesContext;
    results: {
      [
        key: string
      ]: DetailedMetricDistributionWithUncertaintyUnionFloatIntNoneType;
    };
  };
export type DistributionUnionFloatIntNoneType = {
  aggregate: string | number | string;
  value?: number | number | null;
};
export type DetailedMetricDistributionUnionFloatIntNoneType = {
  average?: number | null;
  median?: number | null;
  range?: (number | number)[] | null;
  averageDistribution: DistributionUnionFloatIntNoneType[];
};
export type TimeSeriesIndicatorDistributionUnionFloatIntNoneType = {
  meta: Meta;
  context: TimeSeriesContext;
  results: {
    [key: string]: DetailedMetricDistributionUnionFloatIntNoneType;
  };
};
export type Change = {
  gain?: number | number | null;
  loss?: number | number | null;
  net?: number | number | null;
};
export type DistributionChange = {
  aggregate: string | number | string;
  value?: Change | null;
};
export type DetailedMetricDistributionChange = {
  average?: number | null;
  median?: number | null;
  range?: (number | number)[] | null;
  averageDistribution: DistributionChange[];
};
export type TimeSeriesIndicatorDistributionChange = {
  meta: Meta;
  context: TimeSeriesContext;
  results: {
    [key: string]: DetailedMetricDistributionChange;
  };
};
export type ProjectContext = {
  projectId: number;
  runId?: number | null;
};
export type MetricsSummary = {
  forestCover?: number | number;
  canopyHeightMean?: number | number;
  treeHeightMean?: number | number;
  biomass?: number | number;
  carbon?: number | number;
  co2eq?: number | number;
};
export type MetricResult = {
  meta?: Meta | null;
  context: ProjectContext;
  results: MetricsSummary;
};
export type IndicatorContext = {
  projectId: number;
  runId?: number | null;
  indicators: string[];
  aggregationLevel: string;
  aggregationLevelValues?: (string | number)[] | null;
};
export type DetailedMetric = {
  average?: number | null;
  median?: number | null;
  range?: (number | number)[] | null;
  averageDistribution: any[];
};
export type Indicator = {
  meta: Meta;
  context: IndicatorContext;
  results: {
    [key: string]: DetailedMetric;
  };
};
export type GeoDistributionWithUncertainty = {
  aggregate: string | number | string;
  value?: any | null;
  area: number | number;
  confidenceLowerBound?: number | number | null;
  confidenceUpperBound?: number | number | null;
};
export type StockFeature = {
  id: string;
  type: string;
  properties?: GeoDistributionWithUncertainty | null;
  geometry: Geometry;
};
export type FeatureCollectionStockFeature = {
  type: string;
  features: StockFeature[];
};
export type GeoDistributionChange = {
  aggregate: string | number | string;
  value?: Change | null;
  area: number | number;
};
export type ChangeFeature = {
  id: string;
  type: string;
  properties?: GeoDistributionChange | null;
  geometry: Geometry;
};
export type FeatureCollectionChangeFeature = {
  type: string;
  features: ChangeFeature[];
};
export type GisFile = {
  /** Geographic Information System representing the distribution of a given metric.
            May include _'forest_cover'_, _'canopy_height_mean'_, _'tree_height_mean'_,  _'living_biomass'_ ... */
  name: string;
  /** file extension such as _tif_ */
  extension: string;
};
export type GisFiles = {
  GISFiles: GisFile[];
};
export type VariationGisFileByRequest = {
  /** Geographic Information System file representing the variation of a given metric
            between two given dates.
            May include _'forest_cover'_, _'canopy_height_mean'_, _'tree_height_mean'_,
            _'living_biomass'_ ... */
  name: string;
  /** file extension such as _tif_ */
  extension: string;
  compareToRequestId: string;
  compareToYear: number;
};
export type VariationGisFilesByRequest = {
  variationGISFilesByRequest: VariationGisFileByRequest[];
};
export type VariationGisFile = {
  /** Geographic Information System file representing the variation of a given metric
            between two given dates.
            May include _'forest_cover'_, _'canopy_height_mean'_, _'tree_height_mean'_,
            _'living_biomass'_ ... */
  name: string;
  /** file extension such as _tif_ */
  extension: string;
  compareToRequestId: string;
  compareToYear: number;
};
export type VariationGisFiles = {
  variationGISFiles: VariationGisFile[];
};
export type BodyRegisterRegisterPost = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organisation: string;
};
export type UserRoles =
  | "KANOP_ADMINISTRATOR"
  | "KANOP_DEVELOPER"
  | "CUSTOMER"
  | "PROJECTS_OWNER"
  | "PROJECTS_APPLICATION"
  | "PROJECTS_READER"
  | "ORGANIZATION_PROJECTS_OWNER"
  | "PROJECTS_VIEWER";
export type AuthenticatedUser = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | string | null;
  isActive: boolean;
  organisation: string;
  role?: UserRoles | string | null;
};
export type LoginResponse = {
  accessToken: string;
  tokenType?: string;
  refreshToken: string;
  expiresIn: number;
};
export type BodyLoginLoginPost = {
  grant_type?: string;
  email: string;
  password: string;
  scope?: string;
  client_id?: string | null;
  client_secret?: string | null;
};
export type BodyResetPasswordPasswordPatch = {
  password: string;
};
export type ApplicationCredentialsResponse = {
  clientId: string;
  clientSecret: string;
};
export type ApplicationCreationPayload = {
  role?: UserRoles;
  description?: string | null;
  expiresAt?: string | null;
};
export type ApplicationSummaryResponse = {
  applicationUuid: string | string;
  clientId: string;
  createdAt: string | string;
  expiresAt: string;
  description?: string | null;
  isActive: boolean;
};
export type ApplicationsResponse = {
  applications: ApplicationSummaryResponse[];
};
export type ApplicationResponse = {
  applicationUuid: string | string;
  clientId: string;
  clientSecret: string;
  isActive: boolean;
  createdAt: string | string;
  description?: string | null;
  expiresAt: string | string;
};
export type InviteePayload = {
  email: string;
  roleId: number;
  expiresAt?: string | string | null;
};
export type OrganizationSummary = {
  organizationId: string;
  label: string;
};
export type OrganizationsResponse = {
  organizations: OrganizationSummary[];
};
export type OrganizationCreationResponse = {
  organizationId: string;
};
export type OrganizationPayload = {
  label: string;
  website?: string | null;
  location?: string | null;
  description?: string | null;
};
export type OrganizationDataResponse = {
  organizationId: string;
  label: string;
  website?: string | null;
  location?: string | null;
  createdByIdentity: string;
  createdAt: string;
  adminIdentities: string[];
  memberCount?: number | null;
};
export type MemberData = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  invitedByIdentity?: string | null;
  acceptedAt?: string | null;
  expiresAt?: string | null;
};
export type MembersResponse = {
  members: MemberData[];
};
export type RoleData = {
  roleId: number;
  label: string;
};
export type RolesResponse = {
  roles: RoleData[];
};
export type InviteeData = {
  email: string;
  role: string;
  invitedByIdentity?: string | null;
  expiresAt?: string | null;
};
export type InviteesResponse = {
  invitees: InviteeData[];
};
export const {
  useCreateNewRunProjectsProjectIdRunsPostMutation,
  useSetProjectDetailsProjectsProjectIdPatchMutation,
  useSetRunStatusProjectsProjectIdRunsRunIdPatchMutation,
  useListJobRequestWorkloadsRequestsGetQuery,
  useSetJobRequestStatusWorkloadsRequestsRequestIdPatchMutation,
  useCreateJobForRequestWorkloadsRequestsRequestIdJobsPostMutation,
  useListJobsWorkloadsRequestsRequestIdJobsGetQuery,
  useGetJobWorkloadsRequestsRequestIdJobsJobIdGetQuery,
  useSetJobStatusWorkloadsRequestsRequestIdJobsJobIdPatchMutation,
  usePingPingGetQuery,
  useGetVersionVersionGetQuery,
  useCreateCollectionCollectionsPostMutation,
  useGetCollectionsCollectionsGetQuery,
  useDeleteCollectionsCollectionsDeleteMutation,
  useUpdateCollectionDetailsCollectionsCollectionIdPatchMutation,
  useCreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostMutation,
  useListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetQuery,
  useUploadGtForPlotsCollectionsCollectionIdPlotsPostMutation,
  useUpdateGtForPlotsCollectionsCollectionIdPlotsPatchMutation,
  useGetPlotsForCollectionsCollectionsCollectionIdPlotsGetQuery,
  useDeletePlotsCollectionsCollectionIdPlotsDeleteMutation,
  useUploadGtWithFilesCollectionsCollectionIdFilesPostMutation,
  useListFilesCollectionsCollectionIdFilesGetQuery,
  useGetPlotByIdCollectionsCollectionIdPlotsPlotIdGetQuery,
  useGetProjectReferencesReferencesGetQuery,
  useCreateProjectPostMutation,
  useGetProjectsGetQuery,
  useDeleteCustomerProjectProjectIdDeleteMutation,
  usePatchCustomerProjectProjectIdPatchMutation,
  useGetProjectProjectIdGetQuery,
  useCreatePolygonProjectIdPolygonsPostMutation,
  useDeletePolygonProjectIdPolygonsDeleteMutation,
  useGetPolygonsForProjectProjectIdPolygonsGetQuery,
  useGetAggregationLevelForProjectProjectIdAggregationLevelsGetQuery,
  useGetAggregationLevelValuesForProjectProjectIdAggregationLevelsLevelGetQuery,
  useCreateForestCoverDefinitionConfigurationForProjectProjectIdConfigurationsForestCoverDefinitionPostMutation,
  useCreateAllometricRelationshipConfigurationForProjectProjectIdConfigurationsAllometricRelationshipsPostMutation,
  useCreateRecalibrationCollectionLinkForProjectProjectIdConfigurationsRecalibrationPostMutation,
  useGetConfigurationProjectIdConfigurationsGetQuery,
  useUploadGroundTruthsProjectIdFieldMeasurementsPostMutation,
  useGetFieldMeasurementListProjectIdFieldMeasurementsGetQuery,
  useCreateDataRequestProjectIdRequestsPostMutation,
  useGetRequestsProjectIdRequestsGetQuery,
  useGetDataRequestProjectIdRequestsRequestIdGetQuery,
  useGetProjectTsEvolutionIndicatorsProjectIdEvolutionIndicatorsGetQuery,
  useGetProjectTsVariationIndicatorsProjectIdVariationIndicatorsGetQuery,
  useGetProjectTsChangeIndicatorsProjectIdChangeIndicatorsGetQuery,
  useGetSummaryMetricsForRequestProjectIdRequestsRequestIdMetricsGetQuery,
  useGetProjectIndicatorsForRequestProjectIdRequestsRequestIdMetricsIndicatorsGetQuery,
  useGetStockMetricForPolygonsProjectIdRequestsRequestIdGeoMetricsIndicatorGetQuery,
  useGetChangeMetricForPolygonProjectIdRequestsRequestIdGeoChangeIndicatorGetQuery,
  useGetAvailableFilesForRequestProjectIdRequestsRequestIdGisFilesGetQuery,
  useDownloadGisFileForRequestProjectIdRequestsRequestIdGisFilesGisFileNameGetQuery,
  useGetVariationGisFilesForRequestsProjectIdRequestsRequestIdVariationGisFilesByRequestGetQuery,
  useGetVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesGetQuery,
  useDownloadVariationGisFilesByRequestForRequestByNameProjectIdRequestsRequestIdVariationGisFilesByRequestVariationGisFileNameGetQuery,
  useDownloadVariationGisFilesProjectIdRequestsRequestIdVariationGisFilesVariationGisFileNameGetQuery,
  useRegisterRegisterPostMutation,
  useReadUsersMeMeGetQuery,
  useActivateAccountConfirmGetQuery,
  useActivateAccountConfirmOptionsMutation,
  useLoginLoginPostMutation,
  useLogoutLogoutPutMutation,
  useRequestForgotPasswordTokenPasswordForgetPostMutation,
  useResetPasswordPasswordPatchMutation,
  useCreateApplicationForUserApplicationsPostMutation,
  useGetApplicationsForUserApplicationsGetQuery,
  useGetApplicationForClientIdApplicationsClientIdGetQuery,
  useDeleteApplicationByClientIdApplicationsClientIdDeleteMutation,
  useUpdateApplicationByClientIdApplicationsClientIdPatchMutation,
  useInviteUserToAppInviteesPostMutation,
  useInviteUserToAppInviteesOptionsMutation,
  useGetOrganizationsOrganizationsGetQuery,
  useCreateOrganizationOrganizationsOptionsMutation,
  useCreateOrganizationOrganizationsPostMutation,
  useGetOrganizationByIdOrganizationsOrganizationIdGetQuery,
  useGetOrganizationByIdOrganizationsOrganizationIdOptionsMutation,
  useGetMembersByOrganizationIdOrganizationsOrganizationIdMembersGetQuery,
  useDeleteMemberOrganizationsOrganizationIdMembersOptionsMutation,
  useDeleteMemberOrganizationsOrganizationIdMembersDeleteMutation,
  useGetRolesByOrganizationIdOrganizationsOrganizationIdRolesGetQuery,
  useGetRolesByOrganizationIdOrganizationsOrganizationIdRolesOptionsMutation,
  useGetInviteesByOrganizationIdOrganizationsOrganizationIdInviteesGetQuery,
  useInviteUserToOrganizationOrganizationsOrganizationIdInviteesOptionsMutation,
  useInviteUserToOrganizationOrganizationsOrganizationIdInviteesPostMutation,
  useAcceptInvitationOrganizationsOrganizationIdAcceptPatchMutation,
  useAcceptInvitationOrganizationsOrganizationIdAcceptOptionsMutation,
} = injectedRtkApi;
