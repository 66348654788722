import {
  Box, Input, Grid, Typography, Button, Autocomplete, ListItem,
  Stack,
} from '@mui/joy';
import { LandCoverClassDetail, LandCoverClassesResponse, PlotProperties } from 'api/openapi/generated/RawApi';
import KanopApi from 'api/openapi/KanopApi';
import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { useParams } from 'react-router-dom';

const LandCoverSelector = (
  {
    formState, setFormState,
    disabled,
    landCover,
    refetch,
  }:{
      formState: PlotProperties,
      landCover: LandCoverClassesResponse | undefined,
      setFormState: (value: React.SetStateAction<PlotProperties>) => void
      disabled?: boolean,
      refetch: () => void,
    },
) => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const [landCoverOptions, setLandCoverOptions] = useState<LandCoverClassDetail[]>([]);
  const [newLandCover, setNewLandCover] = useState(false);
  const [inputValues, setInputValues] = useState<string|undefined>();
  const [selectedColor, setSelectedColor] = useState<LandCoverClassDetail | null>(null);
  const [autoCompleteInput, setAutoCompleteInput] = useState('');
  const [ColorError, setColorError] = useState<boolean>(false);

  const [availableLandcoverColors] = useState([
    { color: '#DA0C81' },
    { color: '#C80036' },
    { color: '#F4CE14' },
    { color: '#1E5128' },
    { color: '#E3651D' },
    { color: '#4CAF50' },
    { color: '#381E15' },
    { color: '#E53935' },
    { color: '#FF4081' },
  ]);

  useEffect(() => {
    if (landCover?.classes) {
      setLandCoverOptions(landCover.classes.map((lc) => ({
        label: lc.label,
        color: lc.color,
        classId: lc.classId,
        owner: lc.owner,
      })));
    }
  }, [landCover]);

  const handleColorSelect = (option: LandCoverClassDetail) => {
    setSelectedColor(option);
  };

  const switchToCreateLandcover = () => {
    setNewLandCover(true);
    setInputValues(autoCompleteInput);
  };

  const [
    addLandCoverClass, {
      isLoading: isAddingLandCover,
    }] = KanopApi.useCreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostMutation();

  const addLandCover = () => {
    if (!selectedColor) {
      setColorError(true);
      return;
    }
    if (inputValues && selectedColor) {
      setLandCoverOptions((prevLandCover) => {
      // Check if the label already exists in the land cover options
        const labelExists = prevLandCover.some((landCovers) => landCovers.label === inputValues);

        if (labelExists) {
          return prevLandCover; // Return the existing array without changes
        }

        // If label doesn't exist, proceed with adding the new land cover class
        addLandCoverClass({
          collectionId: collectionId as string,
          landCoverClasses: [{
            label: inputValues,
            color: selectedColor.color,
          }],
        }).unwrap().then((response) => {
          const newLandCoverClassId = response.classes[0].classId;
          const updatedLandCoverOptions = [
            ...prevLandCover,
            {
              label: inputValues,
              color: selectedColor.color || '#000', // default color if none provided
              classId: newLandCoverClassId,
              owner: '', // Add the owner property here if necessary
            },
          ];

          setLandCoverOptions(updatedLandCoverOptions);

          setFormState((prev) => ({
            ...prev,
            landCoverClassId: newLandCoverClassId,
          }));
          setInputValues('');
          setSelectedColor(null);
          setNewLandCover(false);
          refetch();
        });
        return prevLandCover; // Return the existing array as the addition happens asynchronously
      });
    } else {
      setSelectedColor(null);
      setInputValues(undefined);
    }
  };

  return (
    <div className="form-group" id="landCover">
      {newLandCover ? (
        <Box>
          <Stack direction="row" width="auto" spacing={2} alignItems="center">
            <Input
              type="text"
              required
              value={inputValues}
              size="sm"
              onChange={(e) => {
                if (e.target.value === '') {
                  setInputValues(undefined);
                  return;
                }
                setInputValues(e.target.value);
              }}
              placeholder="Enter new land cover class *"
              sx={{ fontSize: '14px', width: '250px' }}
            />
            <Typography
              width="10px"
              onClick={() => {
                setNewLandCover(false);
                setInputValues(undefined);
                setSelectedColor(null);
                setFormState((prev) => ({
                  ...prev,
                  landCoverClassId: undefined,
                }));
              }}
            >
              <CgClose />
            </Typography>
          </Stack>
          <Grid container spacing={1} marginX="auto" marginTop={1}>
            {availableLandcoverColors.map((option) => (
              <Grid key={option.color}>
                <Box
                  sx={{
                    backgroundColor: option.color,
                    cursor: 'pointer',
                    width: selectedColor?.color === option.color ? '15px' : '20px',
                    height: selectedColor?.color === option.color ? '15px' : '20px',
                    borderRadius: '20%',
                    // smooth transition for color selection change
                    transition: 'width 0.2s, height 0.2s',
                  }}
                  onClick={() => handleColorSelect((option as LandCoverClassDetail))}
                />
              </Grid>
            ))}
          </Grid>
          {ColorError && (
            <Typography variant="plain" marginBottom={0} paddingBottom={0} color="danger" level="body-sm">
              Make sure a color is selected
            </Typography>
          )}
          <Button
            size="sm"
            onClick={addLandCover}
            loading={isAddingLandCover}
            sx={{
              marginTop: 1,
              marginX: 'auto',
              display: 'flex',
            }}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Autocomplete
          options={landCoverOptions}
          disabled={disabled}
          value={landCoverOptions.find(
            (option) => option.classId === formState.landCoverClassId,
          ) || null}
          placeholder="Land cover"
          onChange={(event, newValue) => {
            setFormState({
              ...formState,
              landCoverClassId: newValue?.classId === ('' || null || undefined) ? undefined : (newValue as LandCoverClassDetail).classId,
            });
          }}
          filterOptions={(options, { inputValue }) => {
            setAutoCompleteInput(inputValue);
            const filtered = options.filter(
              (option) => option.label.toLowerCase().includes(inputValue.toLowerCase()),
            );
            if (filtered.length === 0) {
              return [{
                label: 'Add Option',
                color: '',
                classId: '',
                owner: '', // Ensure all required fields are provided
                isButton: true,
              } as LandCoverClassDetail];
            }
            return filtered;
          }}
          sx={{
            width: '100%',
            '& .MuiAutocomplete-inputRoot': {
              padding: '0',
            },
            fontSize: '14px',
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(
            props,
            option: { label: string; color: string | null; isButton?: boolean },
          ) => (
            <ListItem {...props} component="li">
              {option?.isButton ? (
                <Button variant="plain" size="sm" onClick={switchToCreateLandcover}>
                  Add new class +
                  <Typography marginLeft={1} level="body-xs">{autoCompleteInput}</Typography>
                </Button>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: option.color || '#000', // default color if none provided
                      marginRight: 1,
                      borderRadius: '30%',
                    }}
                  />
                  <Typography level="body-sm" variant="plain">
                    {option.label}
                  </Typography>
                </Box>
              )}
            </ListItem>
          )}
          noOptionsText={<Box>No options available</Box>}
        />
      )}
    </div>
  );
};

export default LandCoverSelector;
