import {
  Button, Card, Modal, ModalClose, ModalDialog, Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import mapboxgl, { GeoJSONFeature, MapEventType } from 'mapbox-gl';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import * as turf from '@turf/turf';
import PopupForm from 'components/ground truth/Modals/PopUpForm';
import PlotList from 'components/ground truth/Modals/PlotList';
import KanopApi from 'api/openapi/KanopApi';
import {
  CollectionStatusEnum,
  FeatureCollectionPlotGroundTruth, LandCoverClassDetail, PlotGroundTruth, PlotProperties,
  PlotsResponse,
} from 'api/openapi/generated/RawApi';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SearchByCountry from 'components/ground truth/Inputs/SearchByLocationInput';
import { handleError } from 'pages/security/Login';
import { prettyPrint } from 'utils/StringUtils';
import NotFound from 'pages/default/NotFound';
import Title from 'utils/Title';
import FileUploadPopup from 'components/ground truth/Modals/FileUploadPopup';
import CustomButtonControl from './Control classes/CircleDrawControl';
import RadiusMode from './Control classes/RadiusProviderClass';
import FileUpload from './Control classes/FileUploadControl';
import CustomControlContainer from './Control classes/ControlContainerClass';

/**
 * The Ground truth map component.
 * @returns {JSX.Element} The dashboard.
 */

interface DrawEventType {
  type: MapEventType;
  target: mapboxgl.Map;
}

export default function GTmap(): JSX.Element {
  const { collectionId } = useParams<{ collectionId: string | undefined }>();

  const [mapStyle] = useState('mapbox://styles/devitrylouis/clgukfrtu005c01p6804ea95q'); // consistent map style across the app
  const [opeValidatedPlotsPopup, setopeValidatedPlotsPopup] = useState<boolean>(false);
  const [reviewPlotPopup, setReviewPlotPopup] = useState<boolean>(false);
  const [currentFeature, setCurrentFeature] = useState<PlotGroundTruth[] | null>([]);
  const [collectionLocked, setCollectionLocked] = useState<boolean>(false);
  const [collectionStatus, setCollectionStatus] = useState<CollectionStatusEnum>();
  const [confirmValidation, setConfirmValidation] = useState<boolean>(false);
  const [showMarker, setShowMarker] = useState<boolean>(false);
  const [colorMapping, setColorMapping] = useState<{ [key: string]: string }>({});
  const [viewSinglePlot, setViewSinglePlot] = useState<boolean>(false);
  const [openFileUploadPopup, setOpenFileUploadPopup] = useState<boolean>(false);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker }>({});
  const [
    feature,
    setFeature,
  ] = useState<FeatureCollectionPlotGroundTruth | undefined>();

  const [, setSuccess] = useState<boolean>(false);

  const [
    featureCollection,
    setFeatureCollection,
  ] = useState<FeatureCollectionPlotGroundTruth[]>([]);
  const featureCollectionRef = useRef(featureCollection);
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false);

  const [plotsData, setPlotsData] = useState<PlotsResponse | undefined>();
  // State to store all drawn features properties eg: measurements data and name
  const [properties, setProperties] = useState<PlotProperties>();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);

  useEffect(() => {
    if (mapContainerRef.current && !mapRef.current) {
      // Initialize map if it's not already initialized
      mapRef.current = new mapboxgl.Map({
        accessToken: process.env.REACT_APP_MB_TOKEN as string,
        container: mapContainerRef.current,
        style: mapStyle,
        center: [20, 40.50],
        attributionControl: false,
      });

      mapRef.current.on('load', () => {
        // Add the draw control to the map
        drawRef.current = new MapboxDraw({
          controls: {
            point: false,
            line_string: false,
            polygon: true,
            trash: true,
            combine_features: false,
            uncombine_features: false,
          },
          defaultMode: 'simple_select',
          userProperties: true,
          modes: {
            ...MapboxDraw.modes,
            draw_circle: RadiusMode,
          },
        });

        // Create a container for the custom controls
        const controlContainer = document.createElement('span');
        const controlContainer2 = document.createElement('span');

        if (mapRef.current) {
          // instantiate the file upload control
          const fileUploadControl = new FileUpload(
            drawRef.current,
            mapRef.current,
            turf,
            opeValidatedPlotsPopup,
            setCurrentFeature,
            setOpenFileUploadPopup,
          );

          // add second custom control to the container to isolate it from the drawing control tools
          mapRef.current.addControl(new CustomControlContainer(controlContainer2), 'top-left');
          const drawCircle = new CustomButtonControl(drawRef.current);

          // Add the custom drawing controls to the container
          controlContainer.appendChild(drawCircle.onAdd(mapRef.current));
          controlContainer.appendChild(drawRef.current.onAdd(mapRef.current));
          controlContainer2.appendChild(fileUploadControl.onAdd(mapRef.current));

          // Add the first container control to the map
          mapRef.current.addControl(new CustomControlContainer(controlContainer), 'top-left');

          // Add event listeners for draw events
          mapRef.current.on('draw.create' as MapEventType, handleDrawCreate);
          mapRef.current.on('draw.delete' as MapEventType, handleDrawDelete);
          mapRef.current.on('draw.selectionchange' as MapEventType, handleDrawSelect);
          mapRef.current.on('draw.update' as MapEventType, handleDrawUpdate);

          mapRef.current.on('click', (e) => {
            if (!mapRef.current) return;
            // Get features at the clicked point
            const features = mapRef.current.queryRenderedFeatures(e.point);
            if (features && features.length > 0) {
              setProperties(features[0].properties as PlotProperties);
              setOpenFormPopup(true);
            } else {
              setOpenFormPopup(false);
            }
          });

          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          // add a hover event listener to show the plot reference on hover
          mapRef.current?.on('mousemove', (e) => {
            const features = mapRef?.current?.queryRenderedFeatures(e.point);

            if (features && features.length > 0) {
              const featuresss = features[0];
              const { plotReference } = featuresss.properties as PlotProperties;

              if (plotReference) {
                popup
                  .setLngLat(e.lngLat)
                  .setHTML(` <span class="metric-value">Plot reference:  ${plotReference} </span>`)
                  .addTo(mapRef.current!);
              }
            } else {
              popup.remove();
            }
          });
        }
      });
    }

    // Cleanup function to remove the map instance when the component unmounts
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [mapStyle]);

  const [uploadPlot,
    {
      isLoading: isUploading, isError, isSuccess,
    },
  ] = KanopApi.useUploadGtForPlotsCollectionsCollectionIdPlotsPostMutation();

  useEffect(() => {
    const featureMap = new Map();

    // Add features to the map, replacing duplicates based on their id
    featureCollection.forEach((features) => {
      if (Array.isArray(features)) {
        features.forEach((f) => {
          featureMap.set(f.id, f);
        });
      } else {
        featureMap.set((features as unknown as GeoJSONFeature).id, features);
      }
    });

    // Convert map values to an array for the new feature collection
    const uniqueFeatures = Array.from(featureMap.values());

    featureCollectionRef.current = uniqueFeatures;
  }, [featureCollection, feature, isError, isSuccess]);

  // function to handle draw create event
  const handleDrawCreate = (e: DrawEventType) => {
    setProperties(undefined);
    setOpenFormPopup(true);
    setCurrentFeature((e as unknown as FeatureCollectionPlotGroundTruth).features);
  };

  const handleDrawDelete = (e: DrawEventType) => {
    if (!featureCollection) return;
    const deletedFeatureIds = (e as unknown as FeatureCollectionPlotGroundTruth)
      .features.map((features) => (features as unknown as GeoJSONFeature).id);
    setFeatureCollection((prevFeatures) => prevFeatures.filter(
      (
        features:
        FeatureCollectionPlotGroundTruth,
      ) => !deletedFeatureIds.includes((features as unknown as GeoJSONFeature).id),
    ));

    setOpenFormPopup(false);
  };

  // create a handle update function, that updates the feature collection when the polygon updates
  const handleDrawUpdate = (e: DrawEventType) => {
  // remove the old feature from the feature collection
    const updatedFeature = (e as unknown as FeatureCollectionPlotGroundTruth).features[0];
    const updatedFeatureId = (updatedFeature as unknown as GeoJSONFeature).id;
    const updatedFeatureCollection = featureCollectionRef.current.filter((eachFeature) => {
      if (eachFeature instanceof Array) {
        return eachFeature.some((f) => f.id === updatedFeatureId);
      }
      return (eachFeature as unknown as GeoJSONFeature).id === updatedFeatureId;
    });

    const updatedFeatureIndex = featureCollectionRef.current.indexOf(updatedFeatureCollection[0]);
    const updatedFeatureCollectionCopy = [...featureCollectionRef.current];
    (updatedFeatureCollectionCopy as unknown as PlotGroundTruth[])[
      updatedFeatureIndex] = updatedFeature;
    setFeatureCollection(updatedFeatureCollectionCopy);
  };

  //  locally store all saved features in the feature and their properties collection state
  useEffect(() => {
    if (!feature) return;
    setFeatureCollection(
      (prevFeatureCollection) => [
        ...prevFeatureCollection,
         feature as unknown as FeatureCollectionPlotGroundTruth],
    );
  }, [feature]);

  // function to handle polygon selection event
  const handleDrawSelect = (e: DrawEventType) => {
    setCurrentFeature((e as unknown as FeatureCollectionPlotGroundTruth).features);
  };

  const navigate = useNavigate();

  const [finalizePlot] = KanopApi.useUpdateCollectionDetailsCollectionsCollectionIdPatchMutation();

  // handle finalize plot
  const handleFinalize = async () => {
    if (drawRef.current) {
      uploadPlot({
        collectionId: collectionId as string,
        featureCollectionPlotGroundTruthInput: {
          type: 'FeatureCollection',
          features: featureCollectionRef.current as unknown as PlotGroundTruth[],
        },
      }).unwrap().then(() => {
        finalizePlot({
          collectionId: collectionId as string,
          payload: {
            finalized: true,
          },
        }).unwrap();

        setSuccess(true);

        setFeatureCollection([]);
        featureCollectionRef.current = [];
        // Clear the map of all drawn features
        drawRef.current?.deleteAll();
        refetch();

        setopeValidatedPlotsPopup(false);

        // wait for 0.5s before redirecting to the ground truths page
        setTimeout(() => {
          navigate('/');
          toast.success('The collection will now be prepared and made available to recalibrate your data requests.');
        }, 500);
      }).catch((e) => {
        setSuccess(false);
        handleError(e.data?.detail, 'form' || 'An error occured');
      });
    }
  };

  // Fetch plots data from the server
  const {
    data: plot,
    isError: plotError,
    refetch, // re-fetch the data when the uploadData changes
  } = KanopApi.useGetPlotsForCollectionsCollectionsCollectionIdPlotsGetQuery({
    collectionId: collectionId as string,
  });

  useEffect(
    () => {
      if (plot) {
        setPlotsData(plot);
      }
    },
    [plot],
  );

  const popupContent = (plotReference: string) => `
   <div class='popup-div'>
        <p class='poly-detail'>
          Plot reference <p class='poly-value'>${plotReference}</p>
        </p>
      </div>
    </div>
  `;

  useEffect(() => {
    if (plot && showMarker) {
      plot.plots.forEach((EachPlot) => {
        const { centroid, plotReference, plotId } = EachPlot;
        const marker = new mapboxgl.Marker({
          color: '#287769',
        })
          .setLngLat(centroid?.coordinates as [number, number])
          .setPopup(new mapboxgl.Popup().setHTML(popupContent(plotReference)))
          .addTo(mapRef.current as mapboxgl.Map);

        markersRef.current[plotId as string] = marker;
      });
    } else {
      Object.values(markersRef.current).forEach((markers) => {
        markers.remove();
      });
      markersRef.current = {};
    }
  }, [plot, showMarker]);

  const { data: collectionsData } = KanopApi.useGetCollectionsCollectionsGetQuery({
    search: '',
  });

  const [collectionName, setCollectionName] = useState<string>('');

  // a useEffect to filter out the collection with thesame id as the collectionId
  useEffect(() => {
    if (collectionsData) {
      const collection = collectionsData.collections.find(
        (col) => col.collectionId === collectionId,
      );
      if (collection) {
        setCollectionName(collection.label);
        setCollectionStatus(collection.status);
        setCollectionLocked(collection.status !== 'DRAFT');
      }
    }
  }, [collectionsData, collectionId]);

  const [trigger] = KanopApi.useLazyGetPlotByIdCollectionsCollectionIdPlotsPlotIdGetQuery();

  const [polygons, setPolygons] = useState<(PlotGroundTruth | undefined)[]>();

  const {
    data: landCover,
    isSuccess: isLandCoverSuccess,
    refetch: refetchLandCover,
  } = KanopApi.useListLandCoverClassesCollectionsCollectionIdLandCoverClassesGetQuery({
    collectionId: collectionId as string,
  });

  const fetchAllPlots = useCallback(async () => {
    try {
      const requests = plotsData?.plots.map(async (eachPlot) => {
        try {
          const res = await trigger({
            collectionId: collectionId as string,
            plotId: eachPlot.plotId as string,
          }).unwrap();

          if (!drawRef.current) {
            return undefined;
          }
          if (!res || !res.plot || !res.plot.features || res.plot.features.length === 0) {
            return undefined;
          }

          if (!mapRef.current) return null;
          if (isLandCoverSuccess || landCover) {
            // Create a color mapping from the land cover data
            const NewColorMapping: Record<string, string> = {};
            landCover?.classes.forEach((cls: LandCoverClassDetail) => {
              NewColorMapping[cls.classId] = cls.color ?? '#888888';
            });
            setColorMapping(NewColorMapping);

            if (mapRef.current.getSource(`plot-${eachPlot.plotId}`)) {
              mapRef.current.removeSource(`plot-${eachPlot.plotId}`);
            }

            mapRef.current.addSource(`plot-${eachPlot.plotId}`, {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: res.plot.features as GeoJSON.Feature[],
              },
            });

            mapRef.current.addLayer({
              id: `plot-${eachPlot.plotId}`,
              type: 'fill', // or any other layer type you need
              source: `plot-${eachPlot.plotId}`,
              paint: {
                'fill-color': [
                  'match',
                  ['get', 'landCoverClassId'],
                  ...Object.entries(NewColorMapping)
                    .flatMap(([classId, color]) => [classId, color]),
                  '#888888', // Default color if no match is found
                ],
                'fill-opacity': 0.8,
              },
            });
          }
          drawRef.current.add({
            type: 'Feature',
            properties: res.plot.features[0].properties,
            geometry: res.plot.features[0].geometry as GeoJSON.Geometry,
          });

          return res.plot.features[0];
        } catch (err) {
          return undefined;
        }
      }) ?? [];
      setPolygons(await Promise.all(requests as Promise<PlotGroundTruth | undefined>[]));
    } catch (error: any) {
      toast.error(handleError(error.data?.detail, 'form') || 'An unexpected error occurred.');
    }
  }, [plotsData?.plots, trigger, collectionId, isLandCoverSuccess, landCover]);

  // Function to remove marker when a plot is deleted
  const removeMarker = (id: string) => {
    if (markersRef.current[id]) {
      markersRef.current[id].remove();
      delete markersRef.current[id];
    }
  };

  useEffect(
    () => {
      if (plotsData) {
        fetchAllPlots();
      }
    },
    [fetchAllPlots, plotsData],
  );

  const setMapBbox = useCallback(() => {
    if (!mapRef.current || !polygons || polygons.length < 1) return;

    let minLng = Infinity;
    let minLat = Infinity;
    let maxLng = -Infinity;
    let maxLat = -Infinity;

    polygons.forEach((eachPolygon) => {
      if (!eachPolygon) return;
      const { geometry } = eachPolygon;

      if (!geometry || geometry.type !== 'Polygon') return;

      geometry.coordinates[0].forEach(([lng, lat]: [number, number]) => {
      // Ensure the latitude and longitude are within valid ranges
        if (lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90) {
          if (lng < minLng) minLng = lng;
          if (lat < minLat) minLat = lat;
          if (lng > maxLng) maxLng = lng;
          if (lat > maxLat) maxLat = lat;
        }
      });
    });

    // Check if valid coordinates were found before trying to fit the map
    if (minLng !== Infinity
       && minLat !== Infinity && maxLng !== -Infinity && maxLat !== -Infinity) {
      const bbox: mapboxgl.LngLatBoundsLike = [[minLng, minLat], [maxLng, maxLat]];

      mapRef.current.fitBounds(bbox, {
        padding: 20,
      });
    }
  }, [polygons]);

  useEffect(
    () => {
      setMapBbox();
    },
    [plotsData, polygons, setMapBbox],
  );

  // Add event listener to the map to show/hide markers based on zoom level
  useEffect(() => {
    if (!mapRef.current) return;
    mapRef.current.on('zoom', () => {
      if (mapRef.current && mapRef.current?.getZoom() < 5) {
        setShowMarker(true);
      } else {
        setShowMarker(false);
      }
    });
  }, []);

  // Function to remove a source and layer from the map
  const removeLayerAndSource = useCallback(
    (map: mapboxgl.Map) => {
      const layers = map.getStyle()?.layers;
      if (layers) {
        layers.forEach((layer) => {
          if (map.getLayer(layer.id) && layer.id.startsWith('plot')) {
            try {
              map.removeLayer(layer.id);
            } catch (error) {
              toast.error('an error occured while updating map data');
            }
          }
        });
      }

      const sources = map.getStyle()?.sources;
      if (sources) {
        Object.keys(sources).forEach((sourceId) => {
          if (map.getSource(sourceId) && sourceId.startsWith('plot')) {
            try {
              map.removeSource(sourceId);
            } catch (error) {
              toast.error('an error occured while updating map data');
            }
          }
        });
      }
    },
    [],
  );

  // Function to add a source and layer to the map
  const addLayerAndSource = useCallback((
    map: mapboxgl.Map,
    layerId: string,
    sourceId: string,
    features: PlotGroundTruth,
    geometry?: GeoJSON.Geometry,
  ) => {
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
    }
    if (map.getSource(sourceId)) {
      map.removeSource(sourceId);
    }
    map.addSource(sourceId, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: features.properties,
        geometry: geometry as GeoJSON.Geometry,
      },
    });
    map.addLayer({
      id: `plot-${features.properties.plotId}`,
      type: 'fill',
      source: sourceId,
      paint: {
        'fill-color': [
          'match',
          ['get', 'landCoverClassId'],
          ...Object.entries(colorMapping)
            .flatMap(([classId, color]) => [classId, color]),
          '#888888', // Default color if no match is found
        ],
        'fill-opacity': 0.8,
      },
    });
  }, [colorMapping]);

  return (
    plotError ? <NotFound /> : (
      <Card
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
          p: 0,
        }}
      >
        <Title text={`Kanop  ${collectionName}`} />
        <div
          ref={mapContainerRef}
          className="mapContainer"
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
          }}
        />
        {/* All elements on the map goes here, includes buttons, controls, and texts  */}
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            padding: 1,
            right: '10px',
            zIndex: 10,
            color: 'white',
          }}
        >
          <Modal
            open={reviewPlotPopup}
            onClose={() => setReviewPlotPopup(false)}
            disableAutoFocus
          >
            <ModalDialog>
              <ModalClose />
              <PlotList
                allFeatures={featureCollectionRef.current}
                map={mapRef.current}
                setOpen={setReviewPlotPopup}
                plotsData={plotsData}
                draw={drawRef.current}
                refetch={refetch}
                setproperties={setProperties}
                setOpenFormPopup={setOpenFormPopup}
                removeMarker={removeMarker}
                popUptitle="Ground Truth Plots"
                collectionLocked={collectionLocked}
                removeLayerAndSource={removeLayerAndSource}
                addLayerAndSource={addLayerAndSource}
                setViewSinglePlot={setViewSinglePlot}
              />
            </ModalDialog>
          </Modal>
          <Stack
            width="fit-content"
            marginLeft="auto"
          >
            <Button
              size="sm"
              variant="solid"
              sx={{
                borderRadius: 'sm',
                marginBottom: 2,
                paddingX: 5,
                backgroundColor: 'white',
                color: '#287769',
                '&:hover': {
                  color: 'white',
                },
              }}
              onClick={() => setReviewPlotPopup(true)}
            >
              View data
            </Button>
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: 'sm',
                paddingX: 5,
                display: collectionLocked ? 'none' : 'inherit',
              }}
              onClick={() => setopeValidatedPlotsPopup(true)}
            >
              Validate
            </Button>
            {
              viewSinglePlot && (
              <Tooltip
                size="sm"
                variant="plain"
                title="Show all available plots on the map"
                placement="top"
              >
                <Button
                  size="sm"
                  variant="soft"
                  color="neutral"
                  sx={{
                    width: 'fit-content',
                    marginX: 'auto',
                    paddingX: 5,
                    marginTop: 2,
                  }}
                  onClick={() => {
                    setMapBbox();
                    setReviewPlotPopup(false);
                    setViewSinglePlot(false);
                  }}
                >
                  View all plots
                </Button>
              </Tooltip>
              )
            }
          </Stack>
          <Modal
            open={opeValidatedPlotsPopup}
            onClose={() => {
              setopeValidatedPlotsPopup(false);
              setSuccess(false);
            }}
          >
            <ModalDialog>
              <ModalClose />
              <Stack>
                <PlotList
                  allFeatures={featureCollectionRef.current}
                  map={mapRef.current}
                  setOpen={setopeValidatedPlotsPopup}
                  plotsData={plotsData}
                  draw={drawRef.current}
                  setproperties={setProperties}
                  setOpenFormPopup={setOpenFormPopup}
                  removeMarker={removeMarker}
                  popUptitle="Ground Truth validation"
                  collectionLocked={collectionLocked}
                  removeLayerAndSource={removeLayerAndSource}
                  addLayerAndSource={addLayerAndSource}
                  setViewSinglePlot={setViewSinglePlot}
                />
                <Stack
                  width="fit-content"
                  marginX="auto"
                  sx={{
                    display: plotsData && plotsData.plots.length < 1 ? 'none' : 'flex',
                  }}
                >
                  <Stack>
                    <Typography level="body-sm">
                      Once validated, a Ground Truth collection cannot be edited any more.
                      The dataset will then be prepared and made available to
                      recalibrate your project data requests.
                    </Typography>
                    {confirmValidation ? (
                      <Stack>
                        <Typography level="body-sm">
                          Are you sure you want to validate this collection?
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          gap={1}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => setConfirmValidation(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleFinalize}
                            sx={{
                              backgroundColor: 'success',
                            }}
                            loading={isUploading}
                          >
                            Validate
                          </Button>
                        </Stack>
                      </Stack>
                    ) : (
                      <Button
                        onClick={() => setConfirmValidation(true)}
                        sx={{
                          width: 'fit-content',
                          marginTop: 1,
                          marginX: 'auto',
                          paddingX: 5,
                          display: collectionLocked ? 'none' : 'block',
                        }}
                        loading={isUploading}
                      >
                        Validate
                      </Button>
                    )}

                  </Stack>
                </Stack>
              </Stack>
            </ModalDialog>
          </Modal>
          <Stack
            sx={{
              marginTop: 2,
              zIndex: 10,
              color: 'white',
            }}
          >
            {
            openFormPopup && (
            <PopupForm
              map={mapRef.current}
              refetchLandCover={refetchLandCover}
              properties={properties}
              draw={drawRef.current}
              setOpen={setOpenFormPopup}
              setproperties={setProperties}
              setPlotData={setPlotsData}
              currentFeature={currentFeature}
              collectionLocked={collectionLocked}
              removeLayerAndSource={removeLayerAndSource}
              landCover={landCover}
              setFeature={
                setFeature as (feature: PlotGroundTruth | undefined) => void
              }
            />
            )
          }
          </Stack>
        </Stack>
        {/* Search by location search Bar */}
        <Stack sx={{
          position: 'absolute',
          top: 0,
          left: 50,
          zIndex: 20,
          width: '20%',
          padding: 1,
          borderRadius: '4px',
          boxShadow: 1,
        }}
        >
          <SearchByCountry map={mapRef.current} />
        </Stack>
        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            gap: 2,
            transform: 'translateX(-50%)',
            zIndex: 20,
            padding: 2,
            borderRadius: '4px',
            boxShadow: 1,
          }}
        >
          <Typography
            variant="plain"
            textAlign="center"
            borderRadius="md"
            fontWeight={600}
            level="body-sm"
            className="dual-background-text"
          >
            {collectionName}

          </Typography>
          <Typography
            level="body-sm"
            variant="outlined"
            className="dual-background-text"
            color={collectionStatus === 'SUBMITTED' ? 'success' : 'neutral'}
          >
            {collectionStatus ? prettyPrint(collectionStatus, true, false) : 'Draft'}
          </Typography>
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            gap: 2,
            transform: 'translateX(-50%)',
            zIndex: 20,
            borderRadius: 'md',
            boxShadow: 1,
            backgroundColor: 'Background',
          }}
        >
          <Stack
            sx={{
              zIndex: 10,
              color: 'white',
            }}
          >
            {
            openFileUploadPopup && (
            <FileUploadPopup
              collectionId={collectionId}
              onClose={() => setOpenFileUploadPopup(false)}
              collectionLocked={collectionLocked}
            />
            )
          }
          </Stack>
        </Stack>
      </Card>
    )
  );
}
